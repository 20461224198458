import React, { useState } from 'react';
import useStyles from '../../assets/jss/online-inventory/online-inventory';
import faCopy from '../../assets/img/iconos/FontAwsome-copy.svg';

import {
  Paper, Grid, Typography, IconButton, ButtonBase, Radio, withStyles, styled, Dialog, DialogContent, DialogActions,
  Button, TextareaAutosize, Tooltip, DialogTitle
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faDownload } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import { getNameOptionSUSIList, getptionSUSIList } from '../../redux/actions/SUSIListsActions';
import { Alert } from '@material-ui/lab';
import { aprovedMissing } from '../../redux/actions/reportMissingActions';
import faFilePdf from '../../assets/img/iconos/FontAwsome (file-pdf).svg';
import { susi_lists } from '../../config';
import { sendMail } from '../../redux/actions/sendMailActions';

const BlueRadio = withStyles({
  root: {
    color: '#9d9ea5',
    '&$checked': {
      color: '#6ce1f5'
    },
    padding: 0
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);


const ReportMissingAsset = (props) => {
  const classes = useStyles();

  const [typeOfProcedure, setTypeOfProcedure] = useState(props.data.typeOfProcedure);
  const [procedureState, setProcedureState] = useState(props.data.stateWitout);
  const [stateWitout, setStateWitout] = useState(props.data.stateWitout);
  const [date, setDate] = useState(props.data.datefiled);
  const [showDialogAproved, setShowDialogAproved] = useState(false)
  const [showDialogTypeMissing, setShowDialogTypeMissing] = useState(false)
  const [observations, setObservations] = useState(props.data.observationsAproved);
  const [display, setDisplay] = useState(false);
  const [typeDocs, setTypeDocs] = useState([]);
  const [docs, setDocs] = useState([]);
  const [notifyState, setNotifyState] = useState(false);

  const configDate = { year: 'numeric', month: 'long', day: 'numeric' };

  const closeDialogTypeMissing = async (change) => {
    if (!change) {
      let state = getptionSUSIList(props.data.stateWitout, props.propsDad.susiList.replacementStatus)

      setStateWitout(state)
      if (props.data.observationsAproved !== null) {
        setObservations(props.data.observationsAproved)
      } else {
        setObservations('')
      }

    } else {
      props.propsDad.dispatch(aprovedMissing(props.data.id, procedureState, observations, props.propsDad.user.numero_documento))
      setDate(new Date())
      let sendMailResp = await sendMailAprovedState()

      if (sendMailResp['error']) {
        console.error('Ha ocurrido un error al enviar el correo de aprobación: ', sendMailResp);
        setNotifyState(false)
      } else {
        console.log(sendMailResp);
        setNotifyState(false)
      }

      if (!props.propsDad.reportMissings.updating) {
        setStateWitout(procedureState)
      }
    }

    setShowDialogAproved(false);
  };

  const sendMailAprovedState = async () => {
    setNotifyState(true)

    let params = {
      subject: `${stateWitout.name} ${props.data.description} (${props.data.placa})`,
      emailEnd: props.data.responsableEmail,
    }

    let content = susi_lists.replacements_states.aproved_id == procedureState.id ? `Señor Usuario(a)\n
    Nos permitimos informarle que los documentos que adjunto de acuerdo al faltante reportado <b>${props.data.description}
    (${props.data.placa})</b>, cumplen con lo estipulado en la norma de bienes de la entidad, por lo que se da inicio al trámite de 
    reclamación por ${typeOfProcedure && typeOfProcedure.name}.\n\n
    Cordialmente\n\n
    Grupo Gestión de Inventarios – Nivel Central` : `Señor Usuario(a)\n
    Nos permitimos informarle que los documentos que adjunto de acuerdo al faltante reportado <b>${props.data.description}
    (${props.data.placa})</b>, NO cumplen con lo estipulado en la norma de bienes de la entidad, por lo que se le solicita hacer las 
    respectivas correcciones en base a las siguientes observaciones:\n
    ${observations}.\n\n
    Cordialmente\n\n
    Grupo Gestión de Inventarios – Nivel Central`

    console.log(params, content, stateWitout);

    let result = await sendMail(params, content)

    return result
  }

  const renderDialogDocuments = () => {
    let razonFaltante = getNameOptionSUSIList(props.data.novedadId, props.propsDad.missingReasons)

    return (
      <Dialog className='dialogReounded dialogDocuments' open={showDialogTypeMissing} aria-labelledby="form-dialog-title">
        <DialogContent style={{ borderRadius: '1rem' }}>
          <p className='tituloCards'>Reclamación ante {typeOfProcedure && typeOfProcedure.name} por {razonFaltante}</p>

          {typeDocs.map((doc, index) => {
            return <Grid key={index} container direction='row' justifyContent='space-between' alignItems='center'>
              <Grid item xs={8} sm={9} lg={9}>
                <p className='uploadItem'>{doc.name}</p>
              </Grid>
              {docs.map(document => {
                if (document[`${doc.id}`]) {
                  return (
                    <Grid key={index} item xs sm lg container justifyContent='flex-end'>
                      <Grid item>
                        <Tooltip title={document[doc.id].name}>
                          <a className='NavElement'
                            href={process.env.REACT_APP_BACKEND_URL + '/assignedAssets/downloadFiles/' + document[`${doc.id}`].REDO_ID}
                            target="_blank" rel='noopener noreferrer'>
                            <img src={faFilePdf} alt='iconoPDF' />
                          </a>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  )
                }
              })}
            </Grid>
          })}
        </DialogContent>

        <DialogActions style={{ alignSelf: 'center', marginBottom: '1.938rem' }}>
          <Button onClick={() => setShowDialogTypeMissing(false)} style={{ width: '90px', padding: '10px 0' }} className='btnSecundary' color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  const handleChangeType = (event) => {
    let option = getptionSUSIList(event.target.value, props.propsDad.susiList.replacementStatus)

    setProcedureState(option);
  };

  const handleObservations = (event) => {
    setObservations(event.target.value)
  }

  const renderDialogApproved = () => {

    return (
      <Dialog className='dialogReounded dialogDocuments' open={showDialogAproved} aria-labelledby="form-dialog-title">
        <DialogContent style={{ borderRadius: '1rem' }}>
          <p className='tituloCards'>Aprobación {typeOfProcedure && typeOfProcedure.name} - {props.data.funcionario}</p>

          <ButtonBase className='aprobacion'>
            <Grid container direction='row'>
              <Grid item xs="auto" container alignItems='center'>
                <Grid item xs={4} style={{ textAlign: 'flex-start' }}>
                  <BlueRadio
                    checked={procedureState.id == susi_lists.replacements_states.aproved_id}
                    onChange={handleChangeType}
                    value={susi_lists.replacements_states.aproved_id}
                    name="radio-button-demo"
                  />
                </Grid>
                <Grid item xs="auto">
                  <Typography className='fontAa' variant="caption" display="block" gutterBottom>Si</Typography>
                </Grid>
              </Grid>

              <Grid item xs="auto" container alignItems='center'>
                <Grid item xs={4} style={{ textAlign: 'flex-start' }}>
                  <BlueRadio
                    checked={procedureState.id == susi_lists.replacements_states.rejected_id}
                    onChange={handleChangeType}
                    value={susi_lists.replacements_states.rejected_id}
                    name="radio-button-demo"
                  />
                </Grid>
                <Grid item xs="auto">
                  <Typography className='fontAa' variant="caption" display="block" gutterBottom>No</Typography>
                </Grid>
              </Grid>
            </Grid>
          </ButtonBase>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant='caption' className='textCards'>
                <label htmlFor='observationsAproved'>Observaciones:</label>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextareaAutosize
                required={procedureState.id == susi_lists.replacements_states.rejected_id}
                minRows={6}
                cols={2}
                autoFocus
                id="observationsAproved"
                type="text"
                value={observations == null ? ' ' : observations}
                onChange={handleObservations}
                className='textArea' />
            </Grid>
            <Grid item xs={12}>
              {display && <Alert id='Alert' severity="error">El campo Observaciones es requerido</Alert>}
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ marginTop: '1rem' }}>
            <Typography variant='caption' className='textCards'>
              <label>Documentos</label>
            </Typography>
          </Grid>
          {typeDocs.map((doc, index) => {
            return <Grid key={index} container direction='row' justifyContent='space-between' alignItems='center'>
              <Grid item xs={8} sm={9} lg={9}>
                <p className='uploadItem'>{doc.name}</p>
              </Grid>
              {docs.map(document => {
                if (document[`${doc.id}`]) {
                  return (
                    <Grid key={index} item xs sm lg container justifyContent='flex-end'>
                      <Grid item>
                        <Tooltip title={document[doc.id].name}>
                          <a className='NavElement'
                            href={process.env.REACT_APP_BACKEND_URL + '/assignedAssets/downloadFiles/' + document[`${doc.id}`].REDO_ID}
                            target="_blank" rel='noopener noreferrer'>
                            <img src={faFilePdf} alt='iconoPDF' />
                          </a>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  )
                }
              })}
            </Grid>
          })}

        </DialogContent>

        <DialogActions style={{ alignSelf: 'center', marginBottom: '0.9rem', width: '100%', justifyContent: 'center' }}>
          <Button onClick={() => closeDialogTypeMissing(false)} style={{ width: '100px' }} className='btnSecundary' color="primary">
            Cancelar
          </Button>
          <Button disabled={stateWitout.id !== susi_lists.replacements_states.based_id || notifyState} onClick={() => {
            if (procedureState.id == susi_lists.replacements_states.rejected_id && observations !== null && observations.length > 0 || procedureState.id == susi_lists.replacements_states.aproved_id)
              closeDialogTypeMissing(true)
          }} className='btnPrimary btnGm' color="primary">
            {notifyState ? 'Notificando...' : 'Guardar y Notificar'}
          </Button>
          <Button disabled={stateWitout.id !== susi_lists.replacements_states.based_id} onClick={() => {
            if (procedureState.id == susi_lists.replacements_states.rejected_id && observations !== null && observations.length > 0 || procedureState.id == susi_lists.replacements_states.aproved_id)
              closeDialogTypeMissing(true)
          }} className='btnPrimary btnA' color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>);
  }

  useEffect(() => {
    let procedure = getptionSUSIList(props.data.typeOfProcedure, props.propsDad.susiList.typeTransact)
    let state = getptionSUSIList(props.data.stateWitout, props.propsDad.susiList.replacementStatus)

    setStateWitout(state)
    setTypeOfProcedure(procedure)
    setProcedureState(state);
  }, [])

  useEffect(() => {
    let typeTansactDocs = props.propsDad.susiList.transactTypesDocuments.filter(document => {
      /* Obtiene la reción entre documentos y transacción */
      if (typeOfProcedure != null && typeof typeOfProcedure == 'object' && document.TRTD_TRANSACTION_TYPE_ID == typeOfProcedure.id && document.TRTD_MIRE_ID == props.data.novedadId) {
        return document
      } else if (typeOfProcedure != null && typeof typeOfProcedure == 'number' && document.TRTD_TRANSACTION_TYPE_ID == props.data.typeOfProcedure && document.TRTD_MIRE_ID == props.data.novedadId) {
        return document
      }
    })

    let typeDcoument = props.propsDad.susiList.typeDcoument.filter(document => {
      //Obtiene lso documentos que son necesarrios según el tipo de transacción
      let find = typeTansactDocs.find(documentFind => {
        if (documentFind.TRTD_DOCUMENT_ID == document.id) {
          return documentFind
        }
      })

      if (find)
        return document
    })

    setTypeDocs(typeDcoument)
  }, [typeOfProcedure])

  useEffect(() => {

    let docsList = []
    typeDocs.map((doc) => {
      if (props.data.docs) {
        let docExist = props.data.docs.map(docDb => {
          if (docDb.REDO_DOCUMENT_TYPE_ID == doc.id)
            return docDb
        })

        docExist.map((docE) => {
          if (docE && docE.REDO_DOCUMENT_TYPE_ID == doc.id) {
            let encode = docE.REPO_PATH.split('/')[2]
            docE.name = encode

            docsList.push({ [`${doc.id}`]: docE })
          }
        })
      }
    })

    setDocs(docsList)
  }, [typeDocs])



  useEffect(() => {
    if (procedureState.id == susi_lists.replacements_states.rejected_id && observations == null || procedureState.id == susi_lists.replacements_states.rejected_id && observations.length == 0) {
      setDisplay(true)
    } else {
      setDisplay(false)
    }
  }, [procedureState, observations])


  const formatNumber = num => String(num).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const formatNumberPoint = num => String(num).replace(/\B(?=(\d{3})+(?!\d)+(?=.\b))/g, ',');


  return (
    <div className={classes.root + ' contenedorAsset'} >
      <Paper variant="outlined" style={{
        padding: '1rem 0', borderRadius: '2rem', border: 0, margin: 0, maxWidth: 'none',
        boxShadow: '3px 3px 20px 0px #d6e0f4', position: 'relative'
      }}
        className={classes.paper}>
        <Grid container className='contenedorAssetGridMayor'>
          <Grid className='contenedorAssetGrid' item xs={12} sm={12} container>
            <Grid className='columnaLogo' item xs sm={3} lg={2} style={{ padding: '0 0.5rem' }} justifyContent='center' container direction="column">
              <IconButton className="asset_icon" >
                <img className={classes.img} alt="Imagen" src={props.url_imagen} />
              </IconButton>
              <Typography align="center" variant="caption" display="block" gutterBottom className='descripcionBien'> {props.data.description}</Typography>
            </Grid>
            <Grid item className='iconsAsset iconsAssetTop' xs sm style={{ alignSelf: 'center' }}>
              <FontAwesomeIcon onClick={() => setShowDialogAproved(true)} style={{
                float: 'right', marginRight: '0.5rem', cursor: 'pointer', fontSize: '1.3rem',
                color: props.data.stateWitout == susi_lists.replacements_states.aproved_id ? '#879EE3' : '#8487A0'
              }}
                icon={faCheckCircle} size='1x' />
            </Grid>
            <Grid className='columnaInfo' item xs={12} sm={9} lg container>
              <Grid item xs container className='contenedorMedio'>
                <Grid item xs={12} container direction='row'>
                  <Grid item container xs={12}>
                    <Grid item xs={4} sm={2} lg={2} xl={2} container direction='column'>
                      <Grid item>
                        <Typography style={{ color: '#5f6273' }} variant="body2" color="textSecondary" className={classes.text}>
                          <b className='bAsset'> Placa: </b>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography style={{ color: '#5f6273' }} variant="body2" color="textSecondary" className={classes.text}>
                          <b className='bAsset'>Serie del bien: </b>
                        </Typography>
                      </Grid>
                      <Grid item className='dependenciaXs'>
                        <Typography style={{ color: '#5f6273' }} variant="body2" color="textSecondary" className={classes.text}>
                          <b className='bAsset'>Estado:</b>
                        </Typography>
                      </Grid>
                      <Grid item className='dependenciaXs'>
                        <Typography style={{ color: '#5f6273' }} variant="body2" color="textSecondary" className={classes.text}>
                          <b className='bAsset'>Valor:</b>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography style={{ color: '#5f6273' }} variant="body2" color="textSecondary" className={classes.text}>
                          <b className='bAsset'>Funcionario: </b>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography style={{ color: '#5f6273' }} variant="body2" color="textSecondary" className={classes.text}>
                          <b className='bAsset'>Tipo solicitud:</b>
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item xs={8} sm lg xl container direction='column' justifyContent='space-between'>
                      <Grid item>
                        <Typography variant="caption" className="textCards">
                          {props.data.placa}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography className="textCards" variant="caption">
                          {props.data.serieBien}
                        </Typography>
                      </Grid>
                      <Grid item className='dependenciaXs'>
                        <Typography variant="caption"
                          style={{
                            color: stateWitout.description, border: `0.1rem solid ${stateWitout.description}`,
                            borderRadius: '0.5rem', padding: '0.000001rem 0.4rem', fontSize: '0.813rem', fontWeight: '600'
                          }}>
                          {stateWitout.name}
                        </Typography>
                      </Grid>
                      <Grid item className='dependenciaXs'>
                        <Typography variant="caption" className="textCards">
                          ${props.data.value != null ? `${props.data.value}`.includes('.') ? formatNumberPoint(props.data.value) : formatNumber(props.data.value) : 0}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography className="textCards" variant="caption">
                          {props.data.funcionario}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography className="textCards" variant="caption">
                          {typeOfProcedure && typeOfProcedure.name}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item xs={4} sm={2} lg={2} xl={2} container direction='column'>
                      <Grid item className='dependenciaLg'>
                        <Typography style={{ color: '#5f6273' }} variant="body2" color="textSecondary" className={classes.text}>
                          <b className='bAsset'>Estado:</b>
                        </Typography>
                      </Grid>
                      <Grid item className='dependenciaLg'>
                        <Typography style={{ color: '#5f6273' }} variant="body2" color="textSecondary" className={classes.text}>
                          <b className='bAsset'>Valor:</b>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography style={{ color: '#5f6273' }} variant="body2" color="textSecondary" className={classes.text}>
                          <b className='bAsset'>Fecha radicación:</b>
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item xs={8} sm container direction='column'>
                      <Grid item className='dependenciaLg'>
                        <Typography variant="caption"
                          style={{
                            color: stateWitout.description, border: `0.1rem solid ${stateWitout.description}`,
                            borderRadius: '0.5rem', padding: '0.000001rem 0.4rem', fontSize: '0.813rem', fontWeight: '600'
                          }}>
                          {stateWitout.name}
                        </Typography>
                      </Grid>
                      <Grid item className='dependenciaLg'>
                        <Typography variant="caption" className="textCards">
                          ${props.data.value != null ? `${props.data.value}`.includes('.') ? formatNumberPoint(props.data.value) : formatNumber(props.data.value) : 0}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="caption" className="textCards">
                          {date != null && new Date(date).toLocaleDateString("es-ES", configDate) + ' ' + new Date(date).toLocaleTimeString('es-ES', { hour12: true })}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {props.data.observations != null &&
                    <Grid item xs={12} container justifyContent='space-between'>
                      <Grid item xs={4} sm={2} lg={2} xl={2}>
                        <Typography variant="caption">
                          <b className='bAsset'>Observaciones faltante:</b>
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography className="textCards" variant="caption">
                          {props.data.observations}
                        </Typography>
                      </Grid>
                    </Grid>}

                  {props.data.observationsAproved != null || observations != null && observations.length > 0 ?
                    <Grid item xs={12} container justifyContent='space-between'>
                      <Grid item xs={4} sm={2} lg={2} xl={2}>
                        <Typography variant="caption">
                          <b className='bAsset'>Observaciones aprobación:</b>
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography className="textCards" variant="caption">
                          {observations.length > 0 ? observations : props.data.observationsAproved}
                        </Typography>
                      </Grid>
                    </Grid> : null}
                </Grid>

                <hr className='hrStyle' />

                <Grid item xs={12} container className='documentos' alignItems='center'>
                  <Grid item xs={4} sm={2} lg={2} xl={2} className='dependenciaLg'>
                    <Typography style={{ color: '#5f6273' }} variant="body2" color="textSecondary" className={classes.text}>
                      <b className='bAsset'>Documentos: </b>
                    </Typography>
                  </Grid>
                  <Grid item xs sm={8} lg={9} xl={9} container alignItems='center'>
                    <Grid item xs="auto" style={{ padding: '0 0.5rem' }}>
                      <img src={faCopy} alt='documentos' onClick={() => setShowDialogTypeMissing(true)} style={{ cursor: 'pointer' }} />
                    </Grid>
                    <Grid item xs="auto" style={{ padding: '0 0.5rem' }}>
                      <Typography className='fontAa' variant="caption" display="block" gutterBottom>
                        {`${props.data.docs ? props.data.docs.length : 0}  ${props.data.docs && props.data.docs.length == 1 ? 'adjunto' : 'adjuntos'}`}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container xs className='dependenciaLg' justifyContent='flex-end'>
                    <Grid item xs>
                      <FontAwesomeIcon onClick={() => setShowDialogAproved(true)} style={{
                        float: 'right', marginRight: '0.5rem', cursor: 'pointer', fontSize: '1.3rem',
                        color: stateWitout.id == susi_lists.replacements_states.aproved_id || stateWitout.id == susi_lists.replacements_states.rejected_id ? '#879EE3' : '#8487A0'
                      }}
                        icon={faCheckCircle} size='1x' />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      {renderDialogApproved()}
      {renderDialogDocuments()}
    </div >
  );
};

export default ReportMissingAsset