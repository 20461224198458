import { Card, CardActions, CardContent, CardHeader, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Radio, RadioGroup, TextField, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React from 'react';
import { connect } from 'react-redux';
import { fetchAssetStages } from '../../redux/actions/assetStagesActions';
import { fetchInventoryHistory } from '../../redux/actions/inventoryHistoryAtions';
import { fetchLocations } from '../../redux/actions/locationsActions';
import { fetchSectionalsUser } from '../../redux/actions/sectionalsActions';
import { fetchAssetsStatus, fetchChangesTypes, fetchMissingReasons, fetchRazonReimpresionPlaca } from '../../redux/actions/SUSIListsActions';
import { withRouter } from 'react-router-dom';
import { fetchAssetSurplus, getDummySurpluses, getSurpluses, saveSurpluses, paramsSearchSurplus, getSurplus } from '../../redux/actions/assetSurplusAction';
import { Autocomplete } from '@material-ui/lab';
import { updateUbication } from '../../redux/actions/ubicationsActions';

const BlueRadio = withStyles({
  root: {
    color: '#9d9ea5',
    '&$checked': {
      color: '#6ce1f5'
    }
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

class InventoryLeftOver extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      placa: '',
      serie: '',
      loadingLocation: false,
      open3: false,
      validando: false,
      headQuarterChs: '',
      locationChs: '',
      listHeadQuarters: false,
      recordsLocation: [],
      paramsFetchSectionals: {
        user_susi_id: props.user.user_susi_id,
      },
      state2: ''
    }
    this.searchLocation = this.searchLocation.bind(this);
    this.selectHeadQuarter = this.selectHeadQuarter.bind(this);
    this.handleClose3 = this.handleClose3.bind(this);
    this.getnameLocation = this.getnameLocation(this);
    this.loading = 'true';
  }

  getnameLocation(idLocation) {
    let dataname = this.props.locations.filter((t) => t.id == idLocation);
    if (dataname.length > 0) {
      return dataname[0].fullname;
    }
    return "";
  }

  async handleClose3() {
    this.setState({ loadingLocation: true })

    let data = await getSurplus({ placa: this.state.placa, serie: this.state.serie });
    //TLA 
    console.log("data en getSurplus")
    console.log(data)
    data.reportName = this.props.user.fullName
    data.complement = this.state.locationChs
    data.idLocation = this.state.recordsLocation

    await saveSurpluses(this.props.user.numero_documento, data)

    let susrplusesList = await getSurpluses();

    data = susrplusesList.filter(i => i.placa == this.state.placa || i.serie == this.state.serie);

    /* if (susrplusesList.filter(i => i.placa == this.state.placa || i.serie == this.state.serie).length > 0) {
      await updateUbication({ idAsset: data[0].id, surpId: null, complement: this.state.locationChs, idLocation: this.state.recordsLocation })
    } */

    this.setState({ loadingLocation: false })
    this.setState({ open3: false });
    this.setState({ validando: false });

    await this.props.dispatch(fetchAssetSurplus({ placa: this.state.placa, serie: this.state.serie }));

    this.props.history.push("/surplusAsset")
  }

  getInformation() {
    const params = {
      user_susi_id: this.props.user.user_susi_id
    };
    this.props.dispatch(fetchInventoryHistory(params));

  }

  componentDidMount() {
    this.getInformation();
    //this.props.dispatch(fetchAssignedAssets(this.state.paramsFetchAssets));
    this.props.dispatch(fetchAssetsStatus());
    this.props.dispatch(fetchChangesTypes());
    this.props.dispatch(fetchAssetStages());
    this.props.dispatch(fetchMissingReasons());
    this.props.dispatch(fetchLocations());
    this.props.dispatch(fetchSectionalsUser(this.state.paramsFetchSectionals));
    this.props.dispatch(fetchRazonReimpresionPlaca());

    this.loading = this.state.listHeadQuarters && this.props.locations.length == 0;
  }

  async onValidate() {
    if ((this.state.placa.length > 0 && this.state.state2 == 'true') || (this.state.serie.length > 0 && this.state.state2 == 'false')) {
      this.setState({ validando: true })
      this.props.dispatch(paramsSearchSurplus({ placa: this.state.placa, serie: this.state.serie }));
      //let susrplusesList = await getSurpluses({ "placa": this.state.placa, "serie": this.state.serie });

      let data = await getSurplus({ placa: this.state.placa, serie: this.state.serie });
      //console.log("en el front regresa de SIAF"); TLA
      //console.log(data); Se adiciona llamado cuando se presuma que colocaron plaqueta que incluye 4 digitos 2 de seccional y 2 de procedencia
      if(this.state.placa!=null && this.state.placa!= undefined
         && data.error != undefined){
          let partePlaca = this.state.placa.substring(4);
          this.setState({ placa: partePlaca });
          //console.log("nueva placa en estado");
          //console.log(this.state.placa);
          data = await getSurplus({ placa: partePlaca, serie: this.state.serie }); 
         }
      if (data != undefined) {
        if (data.name == undefined && data.error == undefined) {
          if (data != null) {
            this.setState({ open3: true })
          } else {
            await this.props.dispatch(fetchAssetSurplus({ placa: this.state.placa, serie: this.state.serie }));
            this.props.history.push({ pathname: "/surplusAsset", state: { fromDashboard: true } });
          }

        } else {
          this.props.dispatch(fetchAssetSurplus({ placa: this.state.placa, serie: this.state.serie }));
          this.setState({ validando: false });
          this.props.history.push("/surplusAsset");
        }
      }
    }
  }

  searchLocation(event, inputValue) {
    let params = {
      texto: inputValue
    };
    this.props.dispatch(fetchLocations(params));
  }

  selectHeadQuarter(event, inputValue) {
    /*let structureHQ = [{
      hg: "Edificio Echeverry (BARBOSA - ANTIOQUIA)",
      records: [
        { name: "Piso 1" },
        { name: "Piso 2" },
        { name: "Piso 3" },
        { name: "Piso 4" },
        { name: "Piso 5" }
      ]
    },{
      hg: "Bunker (Bogotá D.C - Bogotá D.C)",
      records: [
        { name: "Bloque A - Piso 1" },
        { name: "Bloque A - Piso 2" },
        { name: "Bloque A - Piso 3" },
        { name: "Bloque A - Piso 4" },
        { name: "Bloque B - Piso 1" },
        { name: "Bloque B - Piso 2" },
        { name: "Bloque B - Piso 3" },
        { name: "Bloque B - Piso 4" },
        { name: "Bloque B - Piso 5" },
      ]
    },{
      hg: "Zona Industrial (Bogotá D.C - Bogotá D.C)",
      records: [
        { name: "Piso 1" },
        { name: "Piso 2" },
        { name: "Piso 3" }
      ]
    }];*/
    let structHQ = this.props.locations.filter(hqBD => hqBD.fullname == inputValue);
    if (structHQ.length > 0) {
      this.setState({ recordsLocation: structHQ[0].id })
      this.setState({ headQuarterChs: inputValue });
      this.setState({ listHeadQuarters: false });
    } else {
      this.setState({ headQuarterChs: '' });
    }

  }

  renderDialogLocation() {
    // console.log("Estos son las ubicaciones", this.props.locations);
    return (
      <Dialog open={this.state.open3} disableEnforceFocus aria-labelledby="form-dialog-title">
        <DialogTitle className='tittleGris' style={{ paddingBottom: '0' }} id="form-dialog-title">Ubicación del bien</DialogTitle>
        <DialogContent>
          <Autocomplete
            id="searchHQ"
            freeSolo
            style={{ width: 400 }}
            loading={this.loading}
            options={this.props.locations.map((option) => option.fullname)}
            onInputChange={this.searchLocation}
            value={this.state.headQuarterChs}
            onChange={this.selectHeadQuarter}
            renderInput={(params) => (
              <>
                <label className='labelUbication' htmlFor="searchHQ">Sede</label>
                <TextField {...params} margin="normal" variant="outlined" id='searchHQ'
                  className='ubicacionInput'
                  style={{ margin: 0 }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {this.loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }} /></>
            )}
          />
          <label className='labelUbication' htmlFor="Ubicacion">Ubicación</label><br />
          <TextField
            fullWidth
            className='field'
            style={{ margin: 0 }}
            value={this.state.locationChs}
            onChange={e => this.setState({ locationChs: e.target.value })}
            margin="normal"
            variant="outlined"
            type="text"
            id='Ubicacion'
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
{/* TLA adiciona */}
        <Button className="btnSecundary w-auto" onClick={() => {
            this.setState({ loadingLocation: false })
            this.setState({ open3: false });
            this.setState({ validando: false });
          }} disabled={this.state.headQuarterChs == null} color="primary">
            Cancelar
          </Button>
          <Button className='btnPrimary w-auto' onClick={this.handleClose3} 
            disabled={this.state.headQuarterChs.length == 0} color="primary">
            {this.state.loadingLocation ? '...Guardando' : 'Aceptar'}
          </Button>
        </DialogActions>
      </Dialog>);
  }
  handleChangeSwitch2() {
    this.setState({ open3: true });
  };
  render() {
    return (
      <div className='containerReport'>
        <Grid container alignItems="center">
          <Grid item>
            <h1 className='tittleGris'>BIENES EN SU PODER NO REGISTRADOS EN SU INVENTARIO</h1>
          </Grid>
        </Grid>

        <Grid container direction="column" justifycontent="center" alignItems="center">
          <Grid item className='formReportSobrante'>
            <Card>
              <CardHeader className='tittleCardConsulta' title="Consultar bienes" />
              <RadioGroup className='tittleCardConsulta' aria-label="missing_reason" name="missing_reason" value={this.state.state2} onChange={(event) => {
                this.setState({ state2: event.target.value })
              }}>
                <FormControlLabel
                  value={true}
                  control={<BlueRadio />}
                  checked={this.state.state2 == 'true'}
                  label={'Busqueda por placa'} />
                {this.state.state2 == 'true' && <CardContent style={{ padding: '0 3.5rem' }}>
                  <TextField className='field' fullWidth id="placa" value={this.state.placa} onChange={(e) => this.setState({ placa: e.target.value.trim() })} variant="outlined" />
                </CardContent>}

                <FormControlLabel
                  value={false}
                  control={<BlueRadio />}
                  checked={this.state.state2 == 'false'}
                  label={'Busqueda por serie'} />
                {this.state.state2 == 'false' && <CardContent style={{ padding: '0 3.5rem' }}>
                  <TextField className='field' fullWidth id="serie" value={this.state.serie} onChange={(e) => this.setState({ serie: e.target.value.trim() })} variant="outlined" />
                </CardContent>}
              </RadioGroup>
              <CardActions className='FooterForm'>
                <Button className='btnPrimary' style={{ margin: 'auto', marginBlock: '1rem', width: 'auto' }} onClick={e => this.onValidate()}>
                  {this.state.validando ? 'Validando...' : 'Validar'}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
        {this.renderDialogLocation()}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  historyList: state.inventoryHistory,
  params: state.getActualParams,
  withoutFillingOuts: state.withoutFillingOut,
  user: state.user,
  assignedAssets: state.assignedAssets,
  locations: state.locations.locations,
  assetsStatus: state.SUSILists.assetsStatus,
  changesTypes: state.SUSILists.changesTypes,
  missingReasons: state.SUSILists.missingReasons,
  sectionalsUser: state.sectionals,
  stages: state.stages,
  SyncSIAF: state.syncSIAF.isFetching,
})
export default connect(mapStateToProps, null, null, { forwardRef: true })(
  withRouter(InventoryLeftOver)
);