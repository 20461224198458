import React, { useState } from 'react';
import Routers from '../../components/Router/Routers';
// React Router
import { BrowserRouter as Router, Switch, NavLink } from "react-router-dom";

// List Items
import MainListItems from '../../components/Listitems/listItems';

// Clsx
import clsx from 'clsx';

// Material UI Components
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, ListItemIcon, Grid, styled } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import { ListItem, ListItemText } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';


// Material UI Icons
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import UserAvatar from '../../assets/img/userAvatar.png';
import { connect } from 'react-redux';
import Login from './Login';
import fiscaliapng from "../../assets/img/logo_acho_login.png";


const drawerWidth = '335px';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    background: '#F0FFF0'
  },
  textNav: {
    fontSize: '0.875rem',
    margin: '0 0.5rem',
    fontWeight: '400',
  },
  toolbar: {
    paddingRight: 0, // keep right padding when drawer closed
    paddingLeft: 0,
    width: '100%',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '23px 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    backgroundColor: 'transparent',
    color: '#000',
    boxShadow: 'none',
    marginRight: '3.5rem',
    zIndex: theme.zIndex.drawer + 1,
  },
  appBarShift: {
    width: `100%`
  },
  menuButton: {
    marginRight: 0,
  },
  menuButtonHidden: {
    display: 'none',
  },
  fatherTitle: {
    display: 'flex'
  },
  title: {
    fontWeight: '600',
    color: '#00B88B',
    fontSize: '2rem'
  },
  title2: {
    fontWeight: '600',
    color: '#202533',
    fontSize: '1.4rem',
    paddingLeft: '0.5rem'
  },
  titleInline: {
    placeSelf: 'baseline'
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    position: "relative",
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    width: '100%',
    overflow: 'hidden',
    background: '#F9FAFE',
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    maxWidth: 'none'
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  image: {
    width: "230",
    height: "70"
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '20%',
    maxHeight: '20%',
  },
  listItem: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 2,
    top: 20,
    padding: '9px 0',
  },
}));

function MainPage(props) {
  let tokenCreates = '';
  if (props.token.token !== null) {
    tokenCreates = props.token.token;
  }

  /* 
    const [syncComplete, setSyncComplete] = React.useState();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openNoti, setOpenNoti] = React.useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false); 
    const [placement, setPlacement] = React.useState();
    const [anchorElSubMenu, setAnchorElSubMenu] = React.useState(null);
    */
  const [tokenCreate, setTokenCreate] = React.useState(tokenCreates);

  /* const handleClickSubMenu = (event) => {
    setAnchorElSubMenu(event.currentTarget);
  };

  const handleCloseSubMenu = () => {
    setAnchorElSubMenu(null);
  }; */
  // Media Query Material UI

  const match = useMediaQuery('(min-width:320px)');

  // Styles Instance
  const classes = useStyles();

  // Local State
  const [open, setOpen] = useState(match || true);

  // functions for Open Sidebar
  /* const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  } 
  
  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpenNoti((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };
  */

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', background: '#1f2959', height: '100%', overflowX: 'hidden' }}>
      <Toolbar style={{ display: 'flex', flexDirection: 'column', padding: 0 }}>
        <img src={fiscaliapng} style={{ height: '5rem' }} alt="" />
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <img src={UserAvatar} alt="" style={{ boxShadow: 'rgb(0 0 0 / 28%) 5px 5px 10px 0px', borderRadius: '2rem', margin: '0 0.5rem', width: '3.625rem' }} />
          <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'start' }}>
            <Typography
              align="left"
              variant="body1"
              color="inherit"
              className={classes.textNav}
              style={{ color: '#ffff', lineHeight: '1', fontWeight: '600' }} >
              {props.user.fullName}
            </Typography>
            <Typography
              variant="body2"
              color="inherit"
              className={classes.textNav}
              style={{
                color: '#445dc9', textOverflow: 'ellipsis', width: '65%', whiteSpace: 'nowrap',
                overflow: 'hidden', height: '1rem'
              }}
            >
              Usuario
            </Typography>
          </div>
        </div>
        <hr style={{ backgroundColor: '#879ee3', width: '100%' }} />
        <MainListItems />
        <hr style={{ backgroundColor: '#879ee3', width: '100%' }} />
        <ListItem button disableGutters>
          <ListItemIcon className='listItem'>
            <PowerSettingsNewIcon onClick={() => {
              window.sessionStorage.clear();
              window.location.href = '/';
            }} style={{ color: '#879ee3', fontSize: '1.804rem', cursor: 'pointer' }} />
          </ListItemIcon>
          <ListItemText style={{ color: '#879ee3', fontSize: '1.804rem', cursor: 'pointer' }} primary="Salir" />
        </ListItem>
      </Toolbar>
    </Box>
  );

  let culmTime = new Date(window.sessionStorage.getItem('SESION_CULMINATION_TIME'))

  if (culmTime) {
    var numberOfMlSeconds = culmTime.getTime();
    var addMlSeconds = (24 * 60) * 60000;//horas * min * milseconds
    var newDateObj = new Date(numberOfMlSeconds + addMlSeconds);

    if (new Date() >= newDateObj && tokenCreate.length > 0) {
      window.sessionStorage.clear()
      window.location.reload()
    }
  }
  return (
    <>
      {tokenCreate.length == 0 ?
        <Router>
          <Login></Login>
        </Router> :
        <Router>
          <div id="main-root" className={classes.root}>
            <CssBaseline />
            <aside id="menu">
              <div className='mainlist'>
                <MainListItems style={{ height: '100vh' }} />
              </div>
            </aside>

            <section id='contenido'>
              <Box xs={{ display: 'flex' }}>
                <AppBar className={clsx(classes.appBar, open && classes.appBarShift)}>
                  <Toolbar className={classes.toolbar}>
                    <Grid container alignItems='center' justifyContent='space-between'>
                      <Grid item xs={7} sm={8} lg={9} xl={10} className={classes.fatherTitle} id='tittlePrincipal'>
                        {/* <Typography variant="h6" color="inherit" noWrap className={clsx(classes.title, classes.titleInline)}>
                          MARVIN -
                        </Typography> */}
                        <Typography variant="h5" color="inherit" noWrap className={clsx(classes.title2, classes.titleInline)}>
                          MIS EVENTOS
                        </Typography>
                      </Grid>

                      <Grid item xs={9} className={classes.fatherTitle} id='tittleResponsive'>
                        {/* <Typography variant="h6" color="inherit" noWrap className={clsx(classes.title, classes.titleInline)}>
                          MARVIN -
                        </Typography> */}
                        <Typography variant="h5" color="inherit" noWrap className={clsx(classes.title2, classes.titleInline)}>
                          MIS EVENTOS
                        </Typography>
                      </Grid>

                      <Grid item xs={5} sm={4} lg={3} xl={2} container alignItems='center' justifyContent='flex-end' id='ContenedorGneral'>
                        <Grid item xs={1} sm={1} lg={1} xl={2} className='notificaciones1'>
                          <NavLink to="/tonotifys" activeStyle={{ color: '#fff' }}>
                            <IconButton style={{ color: '#fff', padding: 0 }}>
                              <StyledBadge overlap="rectangular" badgeContent={props.notify.notifys.filter(i => i.read == 0).length} color="secondary">
                                <NotificationsIcon className='iconNotifycations' />
                              </StyledBadge>
                            </IconButton>
                          </NavLink>
                        </Grid>

                        <Grid item xs sm={11} lg={11} xl container alignItems='center' justifyContent='space-evenly' className="contenedorNone">
                          <Grid item xs={5} sm={12} lg={12} xl={6}>
                            <Typography
                              align="left"
                              variant="body1"
                              color="inherit"
                              className={classes.textNav}
                              style={{ color: '#5f6273', lineHeight: '1 !important' }} >
                              {props.user.fullName}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="inherit"
                              className={classes.textNav}
                              style={{
                                color: '#445dc9', textOverflow: 'ellipsis', width: '100%', whiteSpace: 'nowrap',
                                overflow: 'hidden', height: '1rem', fontSize: '0.75rem'
                              }}
                            >
                              Usuario
                            </Typography>
                          </Grid>
                          <Grid item xs={5} sm="auto" lg="auto" xl={4}>
                            <img src={UserAvatar} alt="" style={{ boxShadow: 'rgb(0 0 0 / 28%) 5px 5px 10px 0px', borderRadius: '2rem', margin: '0 0.5rem', width: '3.625rem' }} />
                          </Grid>
                          <Grid item xs={2} sm={1} lg={2} xl={2}>
                            <Box className='contenedorNone'>
                              <PowerSettingsNewIcon onClick={() => {
                                window.sessionStorage.clear();
                                window.location.href = '/';
                              }} style={{ color: '#8487a0', fontSize: '1.804rem', cursor: 'pointer' }} />
                            </Box>
                          </Grid>
                        </Grid>

                      </Grid>

                      <Grid item xs="auto" sm lg xl className='contenedorIconButton'>
                        <Box>
                          <NavLink to="/tonotifys" activeStyle={{ color: '#fff' }}>
                            <IconButton style={{ color: '#fff', paddingLeft: 0 }}>
                              <StyledBadge overlap="rectangular" badgeContent={props.notify.notifys.filter(i => i.read == 0).length} color="secondary">
                                <NotificationsIcon className='iconNotifycations' />
                              </StyledBadge>
                            </IconButton>
                          </NavLink>
                        </Box>
                        <IconButton
                          edge="start"
                          color="inherit"
                          aria-label="open drawer"
                          onClick={handleDrawerToggle}
                          sx={{ mr: 2, display: { sm: 'none' } }}
                          className='iconButtonNav'
                        >
                          <MenuIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Toolbar>
                </AppBar>

                <Box component="nav">
                  <Drawer
                    className='drawerNav'
                    container={window.document.body}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                      keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                      display: { xs: 'block', sm: 'none' },
                      '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                  >
                    {drawer}
                  </Drawer>
                </Box>
              </Box>

              <main id="main-list-data" className={classes.content}>
                <Switch>
                  {/* <Routers /> */}
                </Switch>
              </main>
            </section>
          </div>
        </Router>}
    </>
  );
}

const mapStateToProps = (state) => {

  console.log(state);
  return ({
    token: state.getUserToken,
  notify: state.callNotifys,
  outList: state.withoutFillingOut,
  assignedAssets: state.assignedAssets,
  user: state.user,
  locations: state.locations.locations,
  assetsStatus: state.SUSILists.assetsStatus,
  changesTypes: state.SUSILists.changesTypes,
  missingReasons: state.SUSILists.missingReasons,
  sectionalsUser: state.sectionals,
  stages: state.stages,
  SyncSIAF: state.syncSIAF.isFetching,

  })
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  MainPage
);