//import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(1),
      margin: 'auto',
      /* height: 220, */
      marginBottom: 10
    },
    paperOut:{
      backgroundColor: '#ffffa3',
      padding: theme.spacing(1),
      margin: 'auto',
      /* height: 220, */
      maxWidth: 1000,
      marginBottom: 10
    },
    text: {
      padding: 0,
      margin: 0,
      
    },
    image: {
      width: 128,
      height: 128,
    },
    image2: {
      width: 20,
      height: 20,
    },
    img: {
      margin: 'auto',
      display: 'block',
      height: '2.5rem'
    },
  }));
  
  export default useStyles;