import React, { Component } from 'react';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

//import './style.css';
import "../../assets/css/online-inventory.css";
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { CircularProgress } from '@material-ui/core';
import { fetchMissings } from '../../redux/actions/missingActions';
import { fetchReportMissings } from '../../redux/actions/reportMissingActions';

class AutoCompleteMissings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            timeOutID: null,
            textSearch: '',
            isOpen: false,

        };
    }

    debounceSearch(e) {
        let paramActual = this.props.params;
        paramActual.page = 1;
        paramActual.descripcionBien = e;
        console.log(this.props.total);
        this.props.total ? 
        this.props.dispatch(fetchReportMissings(paramActual)) : 
        this.props.dispatch(fetchMissings(paramActual));
    }

    debounceLog = debounce(textBase => this.debounceSearch(textBase), 300);

    handleChange(e) {
        this.setState({ textSearch: e.target.value });
        this.debounceLog(e.target.value);
    }

    render() {
        return (
            <div className="main-container contenedorBuscador">
                <div className="main-container" style={{ backgroundColor: '#F0F2FA', borderRadius: '1rem', alignItems: 'center', marginRight: '7%', padding: '0' }}>
                    <div className="container-icon">
                        <SearchIcon style={{ color: '#828490' }} />
                    </div>
                    <InputBase
                        placeholder="Buscar"
                        value={this.state.textSearch}
                        style={{ fontWeight: '500', color: '#828490' }}
                        onChange={e => this.handleChange(e)}
                    />
                    <div style={{ marginInlineStart: 'auto', placeSelf: 'center', paddingTop: '0.3rem' }} className="container-icon">
                        {this.props.withouts.isFetching || this.props.reportMissings.isFetching ? <CircularProgress disableShrink size='1rem' style={{ backgroundColor: 'trasparent' }}></CircularProgress> : null}
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return ({
    user: state.user,
    params: state.getActualParams,
    withouts: state.missingReducer,
    reportMissings: state.reportMissingReducer
})}
export default connect(mapStateToProps)(AutoCompleteMissings);