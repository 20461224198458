import axios from "axios";
import { DELETE_FILES_ERROR, DELETE_FILES_REQUEST, DELETE_FILES_SUCCESS, UPLOAD_FILES_ERROR, UPLOAD_FILES_REQUEST, UPLOAD_FILES_SUCCESS } from "../types";


export const postFilesWithout = (files, names, assetId) => (dispatch) => {
    const formData = new FormData();

    formData.append('assetId', `${assetId}`);
    names.forEach(name => {
        let filtradoPorNombre = files.filter(file => file[name])

        if (filtradoPorNombre[0][name]) {
            let fileUp = filtradoPorNombre[0][name]
            formData.append('typeDocumentUpload', `${name}`);
            formData.append(`${fileUp.name}`, `${name}`);
            formData.append('files', fileUp);
        }
    })

    dispatch({ type: UPLOAD_FILES_REQUEST });
    axios.post(process.env.REACT_APP_BACKEND_URL + '/assignedAssets/uploadFiles', formData).then(res => {
        dispatch({
            type: UPLOAD_FILES_SUCCESS,
            payload: {
                message: 'Mensaje',
                files: files
            }
        })
    }).catch(error => {
        console.error('Error al guardar archivos del faltante: ', error);
        dispatch({
            type: UPLOAD_FILES_ERROR,
            payload: {
                error: error.toString()
            }
        })
    })
}

export const deleteFilesWithout = (deleteDocs, nameDelete) => (dispatch) => {

    dispatch({ type: DELETE_FILES_REQUEST });
    axios.delete(process.env.REACT_APP_BACKEND_URL + '/assignedAssets/delete/files', { data: { deleteDocs, nameDelete } }).then((data) => {
        dispatch({ type: DELETE_FILES_SUCCESS });
    }).catch(error => {
        console.error('Error al eliminar archivos: ', error);
        dispatch({ type: DELETE_FILES_ERROR, error: error });
    })
}
