export const FETCH_SYNC_SIAF_REQUEST = 'FETCH_SYNC_SIAF_REQUEST';
export const FETCH_SYNC_SIAF_SUCCESS = 'FETCH_SYNC_SIAF_SUCCESS';
export const FETCH_SYNC_SIAF_ERROR = 'FETCH_SYNC_SIAF_ERROR';

export const FETCH_BLOCK_REQUEST = 'FETCH_BLOCK_REQUEST';
export const FETCH_BLOCK_SUCCESS = 'FETCH_BLOCK_SUCCESS';
export const FETCH_BLOCK_ERROR = 'FETCH_BLOCK_ERROR';

export const FETCH_UBICATIONS_ASSETS_REQUEST = 'FETCH_UBICATIONS_ASSETS_REQUEST';
export const FETCH_UBICATIONS_ASSETS_SUCCESS = 'FETCH_UBICATIONS_ASSETS_SUCCESS';
export const FETCH_UBICATIONS_ASSETS_ERROR = 'FETCH_UBICATIONS_ASSETS_ERROR';

export const FETCH_INVENTORY_OFFICERS_REQUEST = 'FETCH_INVENTORY_OFFICERS_REQUEST';
export const FETCH_INVENTORY_OFFICERS_SUCCESS = 'FETCH_INVENTORY_OFFICERS_SUCCESS';
export const FETCH_INVENTORY_OFFICERS_ERROR   = 'FETCH_INVENTORY_OFFICERS_ERROR';

export const FETCH_ASSETS_SURPLUS_REQUEST = 'FETCH_ASSETS_SURPLUS_REQUEST';
export const FETCH_ASSETS_SURPLUS_SUCCESS = 'FETCH_ASSETS_SURPLUS_SUCCESS';
export const FETCH_ASSETS_SURPLUS_ERROR = 'FETCH_ASSETS_SURPLUS_ERROR';

export const FETCH_ASSETS_AUTH_USER_REQUEST = 'FETCH_ASSETS_AUTH_USER_REQUEST';
export const FETCH_ASSETS_AUTH_USER_SUCCESS = 'FETCH_ASSETS_AUTH_USER_SUCCESS';
export const FETCH_ASSETS_AUTH_USER_ERROR = 'FETCH_ASSETS_AUTH_USER_ERROR';

export const FETCH_ASSETS_SURPLUS_COMPLETE_REQUEST = 'FETCH_ASSETS_SURPLUS_COMPLETE_REQUEST';
export const FETCH_ASSETS_SURPLUS_COMPLETE_SUCCESS = 'FETCH_ASSETS_SURPLUS_COMPLETE_SUCCESS';
export const FETCH_ASSETS_SURPLUS_COMPLETE_ERROR = 'FETCH_ASSETS_SURPLUS_COMPLETE_ERROR';

export const FETCH_ASSETS_SURPLUS_TYPELIST_REQUEST = 'FETCH_ASSETS_SURPLUS_TYPELIST_REQUEST';
export const FETCH_ASSETS_SURPLUS_TYPELIST_SUCCESS = 'FETCH_ASSETS_SURPLUS_TYPELIST_SUCCESS';
export const FETCH_ASSETS_SURPLUS_TYPELIST_ERROR = 'FETCH_ASSETS_SURPLUS_TYPELIST_ERROR';

export const FETCH_NOTIFICATIONS_REQUEST = 'FETCH_NOTIFICATIONS_REQUEST';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_ERROR = 'FETCH_NOTIFICATIONS_ERROR';

export const CREATE_NOTIFICATIONS_APROVED_REQUEST = 'CREATE_NOTIFICATIONS_APROVED_REQUEST';
export const CREATE_NOTIFICATIONS_APROVED_SUCCESS = 'CREATE_NOTIFICATIONS_APROVED_SUCCESS';
export const CREATE_NOTIFICATIONS_APROVED_ERROR = 'CREATE_NOTIFICATIONS_APROVED_ERROR';

export const FETCH_INVENTORY_HISTORY_REQUEST = 'FETCH_INVENTORY_HISTORY_REQUEST';
export const FETCH_INVENTORY_HISTORY_SUCCESS = 'FETCH_INVENTORY_HISTORY_SUCCESS';
export const FETCH_INVENTORY_HISTORY_ERROR = 'FETCH_INVENTORY_HISTORY_ERROR';

export const FETCH_ASSIGNED_ASSETS_REQUEST = 'FETCH_ASSIGNED_ASSETS_REQUEST';
export const FETCH_ASSIGNED_ASSETS_SUCCESS = 'FETCH_ASSIGNED_ASSETS_SUCCESS';
export const FETCH_ASSIGNED_ASSETS_ERROR = 'FETCH_ASSIGNED_ASSETS_ERROR';

export const FETCH_ASSIGNED_ASSETS_FOR_PDF_REQUEST = 'FETCH_ASSIGNED_ASSETS_FOR_PDF_REQUEST';
export const FETCH_ASSIGNED_ASSETS_FOR_PDF_SUCCESS = 'FETCH_ASSIGNED_ASSETS_FOR_PDF_SUCCESS';
export const FETCH_ASSIGNED_ASSETS_FOR_PDF_ERROR = 'FETCH_ASSIGNED_ASSETS_FOR_PDF_ERROR';

export const FETCH_ASSETS_STAGES_USER_REQUEST = 'FETCH_ASSETS_STAGES_USER_REQUEST';
export const FETCH_ASSETS_STAGES_USER_SUCCESS = 'FETCH_ASSETS_STAGES_USER_SUCCESS';
export const FETCH_ASSETS_STAGES_USER_ERROR = 'FETCH_ASSETS_STAGES_USER_ERROR';

export const FETCH_SECTIONALS_USER_REQUEST = 'FETCH_SECTIONALS_USER_REQUEST';
export const FETCH_SECTIONALS_USER_SUCCESS = 'FETCH_SECTIONALS_USER_SUCCESS';
export const FETCH_SECTIONALS_USER_ERROR = 'FETCH_SECTIONALS_USER_ERROR';

export const FETCH_ASSETS_CATEGORIES_REQUEST = 'FETCH_ASSETS_CATEGORIES_REQUEST';
export const FETCH_ASSETS_CATEGORIES_SUCCESS = 'FETCH_ASSETS_CATEGORIES_SUCCESS';
export const FETCH_ASSETS_CATEGORIES_ERROR = 'FETCH_ASSETS_CATEGORIES_ERROR';

export const GET_USER = 'GET_USER';
export const GET_ACTUAL_PARAMS = 'GET_ACTUAL_PARAMS';
export const GET_SURPLUS_SEARCH_PARAMS = 'GET_SURPLUS_SEARCH_PARAMS';


export const FETCH_ASSETS_STATUS_REQUEST = 'FETCH_ASSETS_STATUS_REQUEST';
export const FETCH_ASSETS_STATUS_SUCCESS = 'FETCH_ASSETS_STATUS_SUCCESS';
export const FETCH_ASSETS_STATUS_ERROR = 'FETCH_ASSETS_STATUS_ERROR';

export const FETCH_CHANGES_TYPES_REQUEST = 'FETCH_CHANGES_TYPES_REQUEST';
export const FETCH_CHANGES_TYPES_SUCCESS = 'FETCH_CHANGES_TYPES_SUCCESS';
export const FETCH_CHANGES_TYPES_ERROR = 'FETCH_CHANGES_TYPES_ERROR';

export const FETCH_MISSING_REASONS_REQUEST = 'FETCH_MISSING_REASONS_REQUEST';
export const FETCH_MISSING_REASONS_SUCCESS = 'FETCH_MISSING_REASONS_SUCCESS';
export const FETCH_MISSING_REASONS_ERROR = 'FETCH_MISSING_REASONS_ERROR';

export const FETCH_LOCATIONS_REQUEST = 'FETCH_LOCATIONS_REQUEST';
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';
export const FETCH_LOCATIONS_ERROR = 'FETCH_LOCATIONS_ERROR';

export const FETCH_WITHOUT_FILLINGOUT_REQUEST = 'FETCH_WITHOUT_FILLINGOUT_REQUEST';
export const FETCH_WITHOUT_FILLINGOUT_SUCCESS = 'FETCH_WITHOUT_FILLINGOUT_SUCCESS';
export const FETCH_WITHOUT_FILLINGOUT_ERROR = 'FETCH_WITHOUT_FILLINGOUT_ERROR';

export const FETCH_MISSINGS_REQUEST = 'FETCH_MISSINGS_REQUEST';
export const FETCH_MISSINGS_SUCCESS = 'FETCH_MISSINGS_SUCCESS';
export const FETCH_MISSINGS_ERROR = 'FETCH_MISSINGS_ERROR';

export const FETCH_REPORT_MISSINGS_REQUEST = 'FETCH_REPORT_MISSINGS_REQUEST';
export const FETCH_REPORT_MISSINGS_SUCCESS = 'FETCH_REPORT_MISSINGS_SUCCESS';
export const FETCH_REPORT_MISSINGS_ERROR = 'FETCH_REPORT_MISSINGS_ERROR';

export const UPDATE_REPORT_MISSINGS_REQUEST = 'UPDATE_REPORT_MISSINGS_REQUEST';
export const UPDATE_REPORT_MISSINGS_SUCCESS = 'UPDATE_REPORT_MISSINGS_SUCCESS';
export const UPDATE_REPORT_MISSINGS_ERROR = 'UPDATE_REPORT_MISSINGS_ERROR';

export const UPDATE_MISSINGS_REQUEST = 'UPDATE_MISSINGS_REQUEST';
export const UPDATE_MISSINGS_SUCCESS = 'UPDATE_MISSINGS_SUCCESS';
export const UPDATE_MISSINGS_ERROR = 'UPDATE_MISSINGS_ERROR';

export const FETCH_TOTAL_MISSINGS_REQUEST = 'FETCH_TOTAL_MISSINGS_REQUEST';
export const FETCH_TOTAL_MISSINGS_SUCCESS = 'FETCH_TOTAL_MISSINGS_SUCCESS';
export const FETCH_TOTAL_MISSINGS_ERROR = 'FETCH_TOTAL_MISSINGS_ERROR';

export const FETCH_TYPE_TRANSACT_TYPELIST_REQUEST = 'FETCH_TYPE_TRANSACT_TYPELIST_REQUEST';
export const FETCH_TYPE_TRANSACT_TYPELIST_SUCCESS = 'FETCH_TYPE_TRANSACT_TYPELIST_SUCCESS';
export const FETCH_TYPE_TRANSACT_TYPELIST_ERROR = 'FETCH_TYPE_TRANSACT_TYPELIST_ERROR';

export const FETCH_TYPE_DOCUMENT_TYPELIST_REQUEST = 'FETCH_TYPE_DOCUMENT_TYPELIST_REQUEST';
export const FETCH_TYPE_DOCUMENT_TYPELIST_SUCCESS = 'FETCH_TYPE_DOCUMENT_TYPELIST_SUCCESS';
export const FETCH_TYPE_DOCUMENT_TYPELIST_ERROR = 'FETCH_TYPE_DOCUMENT_TYPELIST_ERROR';

export const FETCH_TYPE_REPLACEMENT_STATUS_TYPELIST_REQUEST = 'FETCH_TYPE_REPLACEMENT_STATUS_TYPELIST_REQUEST';
export const FETCH_TYPE_REPLACEMENT_STATUS_TYPELIST_SUCCESS = 'FETCH_TYPE_REPLACEMENT_STATUS_TYPELIST_SUCCESS';
export const FETCH_TYPE_REPLACEMENT_STATUS_TYPELIST_ERROR = 'FETCH_TYPE_REPLACEMENT_STATUS_TYPELIST_ERROR';

export const FETCH_TRANSACT_TYPES_DOCUMENT_REQUEST = 'FETCH_TRANSACT_TYPES_DOCUMENT_REQUEST';
export const FETCH_TRANSACT_TYPES_DOCUMENT_SUCCESS = 'FETCH_TRANSACT_TYPES_DOCUMENT_SUCCESS';
export const FETCH_TRANSACT_TYPES_DOCUMENT_ERROR = 'FETCH_TRANSACT_TYPES_DOCUMENT_ERROR';

export const FETCH_REPRINT_REASON_REQUEST = 'FETCH_REPRINT_REASON_REQUEST';
export const FETCH_REPRINT_REASON_SUCCESS = 'FETCH_REPRINT_REASON_SUCCESS';
export const FETCH_REPRINT_REASON_ERROR = 'FETCH_REPRINT_REASON_ERROR';

export const UPLOAD_FILES_REQUEST = 'UPLOAD_FILES_REQUEST';
export const UPLOAD_FILES_SUCCESS = 'UPLOAD_FILES_SUCCESS';
export const UPLOAD_FILES_ERROR = 'UPLOAD_FILES_ERROR';

export const DOWNLOAD_FILES_REQUEST = 'DOWNLOAD_FILES_REQUEST';
export const DOWNLOAD_FILES_SUCCESS = 'DOWNLOAD_FILES_SUCCESS';
export const DOWNLOAD_FILES_ERROR = 'DOWNLOAD_FILES_ERROR';

export const DELETE_FILES_REQUEST = 'DELETE_FILES_REQUEST';
export const DELETE_FILES_SUCCESS = 'DELETE_FILES_SUCCESS';
export const DELETE_FILES_ERROR = 'DELETE_FILES_ERROR';

export const FETCH_DATA_USER_REQUEST = 'FETCH_DATA_USER_REQUEST';
export const FETCH_DATA_USER_SUCCESS = 'FETCH_DATA_USER_SUCCESS';
export const FETCH_DATA_USER_ERROR   = 'FETCH_DATA_USER_ERROR';