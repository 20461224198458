import { Card, CardActions, CardContent, Typography, Button, CircularProgress } from "@material-ui/core";
import React from 'react'
import { connect } from "react-redux"
import { fetchLogin } from "../../redux/actions/loginSessionAction";
import { Alert } from "@material-ui/lab";
import logoA from '../../assets/img/logo_acho_login.png';
import tituloA from '../../assets/img/titulo_mi_inventario_linea.png';
import {REACT_APP_VERSION} from "../../config";
import { useEffect } from "react";

const Login = (props) => {
    let message = '';
    if (props.token.error) {
        message = 'El usuario no es valido';
    }
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    


    const handleAuth = async () => {
        setLoading(true);
        setTimeout(async () => {
            await props.dispatch(fetchLogin({ username, password }));
        }, 500);
        
    };

    useEffect(() => {
        setLoading(props.token.isFetching);
    }, [props.token])
    

    return (
        <div className="containerLogin">
            <Card className='rootLogin'>
                <CardContent className="cardTtuloLogin">
                    {/* <Typography className="tituloLogin">MI <br /></Typography>
                    <Typography variant="h5" >INVENTARIO</Typography>
                    <Typography variant="h5" >EN LÍNEA</Typography> */}
                    
                </CardContent>
                <img src={tituloA} className='ImgTitulo' alt="" />
                <CardContent style={{ padding: '0.3rem', margin: '0.1rem' }}>
                    <input className="field" value={username} onChange={(e) => setUsername(e.target.value.replace(' ', ''))} placeholder={'Usuario'} required>
                    </input>
                </CardContent>
                <CardContent style={{ padding: '0.3rem', margin: '0.1rem' }}>
                    <input className="field" value={password} type="password" onChange={(e) => setPassword(e.target.value.replace(' ', ''))} placeholder={'Contraseña'} security="true" required>
                    </input>
                </CardContent>
                {message.length > 0 ? <Alert severity="error">{message}</Alert> : <></>}
                <CardActions style={{ justifyContent: 'center' }}>
                    {loading == false ? <Button className="btnLogin" onClick={handleAuth}>Ingresar</Button> : <CircularProgress style={{ margin: 'auto' }} />}
                </CardActions>
                <img src={logoA} className='ImgLogoLogin' alt="" />
                <CardContent style={{ padding: '0.1rem', margin: '0.1rem' }}>
                    <Typography paragraph style={{fontSize:14}}>
                        Versión {REACT_APP_VERSION}
                    </Typography>
                </CardContent>
            </Card>
        </div>
    )
}
function mapStateToProps(state) {
    console.log(state)
    return {
        token: state.getUserToken
    };
}
export default connect(mapStateToProps, null, null, { forwardRef: true })(Login);
