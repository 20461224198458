import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Paper, Grid, Typography, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, CircularProgress,
  Chip, Menu, MenuItem, ButtonBase, Radio, withStyles
} from "@material-ui/core";
import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab";
import { Editor } from "@tinymce/tinymce-react";
import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";
import useStyles from "../../assets/jss/online-inventory/online-inventory";
import { fetchCompleteAssetSurplus, updateSurplus } from "../../redux/actions/assetSurplusAction";
import { fetchLocations } from "../../redux/actions/locationsActions";
import { sendMail } from "../../redux/actions/sendMailActions";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import EditIcon from '@material-ui/icons/Edit';
import { updateUbication } from "../../redux/actions/ubicationsActions";
import { fetchAssetStages } from "../../redux/actions/assetStagesActions";
import { fetchRazonReimpresionPlaca } from "../../redux/actions/SUSIListsActions";

const BlueRadio = withStyles({
  root: {
    color: '#9d9ea5',
    '&$checked': {
      color: '#6ce1f5'
    }
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const AssetlistSurplus = (props) => {

  let saveLocation = false;
  const editorRef = useRef(null);
  const classes = useStyles();
  const [page, setPage] = useState(props.listSurplus.page);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState(props.user.CORREO_REPORTAR_REIMPRESION_PLACA)
  const [ccmail, setCcEmail] = useState('')
  const [asuntoMail, setAsuntoMail] = useState(`Solicitud Renovación de plaqueta adhesiva`)
  const [sended, setSended] = useState(false)
  const [emailAlert, setEmailAlert] = useState('')
  const [menuEdit, setMenuEdit] = useState(false)
  const [state, setState] = useState({
    mouseX: null,
    mouseY: null,
    serie: null,
    placa: null,
    cont: null
  });
  const [cont, setcont] = useState(0)

  const getnameLocation = (idLocation) => {
    let dataname = props.locations.filter((t) => t.id == idLocation);
    if (dataname.length > 0) {
      return dataname[0].fullname;
    }
    return "";
  };
  let location = '';
  let headChs = '';
  if (props.assetSurplus.asset && props.assetSurplus.asset.location != null) {
    location = props.assetSurplus.asset.location;
    headChs = getnameLocation(props.assetSurplus.asset.location);
  }
  let complement = '';
  if (props.assetSurplus.asset && props.assetSurplus.asset.descLocation != null) {
    complement = props.assetSurplus.asset.descLocation;
  }
  const [open3, setOpen3] = useState(saveLocation);
  const [headquarterChs, setHeadQuarterChs] = useState(headChs);
  const [listHeadQuarters, setListHeadQuarters] = useState(false);
  const [locationChs, setLocationChs] = useState(complement);
  const [recordsLocation, setRecordsLocation] = useState(location);
  const loading = listHeadQuarters && props.locations.length === 0;
  const [heightWindow, setHeightWindow] = useState(window.innerHeight - 160);

  const [saveHeadquarterChs, setSaveHeadquarterChs] = useState(headChs);
  const [saveLocationChs, setSaveLocationChs] = useState(complement);
  const configDate = { year: 'numeric', month: 'long', day: 'numeric' };
  const [razonReimpresion, setRazonReimpresion] = useState(null)

  const toggleDialog = (cont = null) => {
    const currentState = open;
    if (!open) {
      setSended(false);
      setEmailAlert('');
      setRazonReimpresion(null);
      setCcEmail([]);
    }
    if (cont != null) { setcont(cont) }
    setOpen(!currentState)
  };

  const toggleMenuEdit = (event, serieF, placa, cont) => {
    const currentState = menuEdit;
    setMenuEdit(!currentState)
    if (menuEdit) {
      setState({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
        serie: serieF,
        placa: placa,
        cont: cont
      });
    } else {
      setState({
        mouseX: null,
        mouseY: null,
        serie: null,
        placa: null,
        cont: null
      });
    }
  }
  const send = async () => {
    if (editorRef.current) {
      setSended(true)
      let params = {
        subject: asuntoMail + `(${props.listSurplus.data[cont].placa})`,
        emailEnd: email,
      }
      let result = await sendMail(params, editorRef.current.getContent(), ccmail)
      if (result) {
        setEmailAlert(result)
      } else {
        setEmailAlert('')
      }
    }
  };

  const templateResponsable = (type = 1) => {
    return type == 1 ? `
        Señor servidor
        <br><br>
        Se le notifica que el bien con placa 
        ${props.listSurplus.data[state.cont].placa == null ? 'no identificado' : props.listSurplus.data[state.cont].placa} y serie 
        ${props.listSurplus.data[state.cont].serieBien == null ? 'no identificado' : props.listSurplus.data[state.cont].serieBien} ha cambiando su estado de: En tramite a tramitado por el usuario 
        ${props.user.fullName}
        <br><br>
        Contacto usuario
        <br><br>
        Correo electronico ${props.user.email}
      `: `
        Señor Inventario
        <br><br>
        Se le notifica que el bien con placa 
        ${props.listSurplus.data[state.cont].placa == null ? 'no identificado' : props.listSurplus.data[state.cont].placa} y serie 
        ${props.listSurplus.data[state.cont].serieBien == null ? 'no identificado' : props.listSurplus.data[state.cont].serieBien} ha cambiando su estado de: En tramite a tramitado
      `;
  }

  const updateOneSurplus = async () => {
    //console.log('state.cont: ', state.cont);
    //console.log('props.listSurplus.data[state.cont]: ', props.listSurplus.data[state.cont].placa);
    let params = {
      subject: 'Bien tramitado ' + `(${props.listSurplus.data[state.cont].placa})`,
      emailEnd: props.user.email
    }
    await updateSurplus(props.user.numero_documento, { placa: state.placa, serie: state.serie })
    props.listSurplus.data[state.cont].estado = 'Tramitado';
    sendMail(params, templateResponsable(2), []).then()
    if (props.listSurplus.data[state.cont].responsable != null) {
      params.emailEnd = props.listSurplus.data[state.cont].email
      sendMail(params, templateResponsable(), []).then()
    }
    let jasper = Object.assign({}, page);
    jasper.page = props.listSurplus.page + 1;
    toggleMenuEdit(null, null, null, null)
    //props.dispatch(fetchCompleteAssetSurplus(jasper.page));
  }

  let dependencys = [
    { title: 'ADMINISTRACION DE SEDE', id: 1 },
    { title: 'ARCHIVO LOFOSCOPIA NACIONAL', id: 2 },
    { title: 'BRIGADA NACIONAL DE HOMICIDIOS', id: 3 },
    { title: 'DELEGADA CONTRA LA CRIMINALIDAD ORGANIZADA', id: 4 },
    { title: 'DELEGADA PARA LA SEGURIDAD CIUDADANA', id: 5 },
    { title: 'DELEGADA PARA LAS FINANZAS CRIMINALES', id: 6 },
    { title: 'DEPARTAMENTO DE ADMINISTRACION DE BIENES Y CONSTRUCCIONES', id: 7 },
    { title: 'DEPARTAMENTO DE ALTOS ESTUDIOS', id: 8 },
    { title: 'DEPARTAMENTO DE BIENES ALMACEN E INVENTARIOS', id: 9 }
  ];

  useEffect(() => {
    props.dispatch(fetchCompleteAssetSurplus(props.listSurplus.page, true))
    props.dispatch(fetchLocations());
    props.dispatch(fetchAssetStages())
    props.dispatch(fetchRazonReimpresionPlaca())
  }, [])

  const fetchMore = () => {
    setTimeout(() => {
      let jasper = Object.assign({}, page);
      jasper.page = props.listSurplus.page + 1;
      props.dispatch(fetchCompleteAssetSurplus(jasper.page));
    }, 1500);
  }

  const getURLAsset = (assetFile) => {
    if (assetFile)
      return (
        "http://" +
        window.location.host +
        "/assets/img/iconsAssets/" +
        assetFile +
        ".svg"
      );
    else
      return (
        "http://" +
        window.location.host +
        "/assets/img/iconsAssets/no-image.svg"
      );
  };

  const templateText = () => {
    return `
    <p style="color: #5f6273 !important; font-size: 0.875rem !important">
      Señores Inventarios
      <br><br>
      Se informa que una vez consultada la información del serie 
      ${props.listSurplus.data[cont].serieBien == null ? 'no identificado' : props.listSurplus.data[cont].serieBien}, el sistema identificó la placa 
      ${props.listSurplus.data.length > 0 ? props.listSurplus.data[cont].placa : 'no identificado'} a nombre de 
      ${props.listSurplus.data[cont].responsable != null ? props.listSurplus.data[cont].responsable : 'no identificado'}
      <br><br>
      Se solicita generar la correspondiente placa de codigo de barras por ${razonReimpresion.name}, con el fin de realizar la legalización.
      <br><br>
      ${props.user.fullName}
      <br><br>
      Correo electronico ${props.user.email}
    </p>`;
  }

  const validateEmailString = (input = '') => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(input)
  }

  const handleChangeTypeReimpresion = (event) => {
    let optionData = props.reprintReasons.find(reimpresion => reimpresion.id == event.target.value)

    setRazonReimpresion(optionData)
  };

  const renderDialog = () => {
    return (
      <Dialog
        className="containerCorreoBienes2"
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {emailAlert.length == 0 && razonReimpresion != null ? <>
          <DialogTitle>{"Enviar correo electrónico"}</DialogTitle>
          <DialogContent>
            <label className='labelStyle' htmlFor='placa'>Para</label>
            <TextField style={{ backgroundColor: 'transparent', color: '#5f6273', marginBottom: 7, borderRadius: '1rem' }} fullWidth id="placa" value={email} size={'small'} variant="outlined" onChange={e => setEmail(e.target.value)} disabled />
            <Autocomplete
              multiple
              id="tags-filled"
              options={[]}
              value={[...ccmail]}
              defaultValue={[...ccmail]}
              freeSolo
              onChange={(event, input) => {
                setCcEmail(input)
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip variant='outlined' style={{ marginLeft: '1rem', backgroundColor: validateEmailString(option) ? '#dae2fc' : '#ef4682', color: validateEmailString(option) ? '#445dc9' : '#ffff', border: 0 }} color={validateEmailString(option) ? 'primary' : 'secondary'} label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <>
                  <label className='labelStyle' htmlFor='CC'>CC</label>
                  <TextField className='inputCC' style={{ marginBottom: 7 }} onBlur={(e) => {
                    if (e.target.value.length > 0) {
                      setCcEmail(ccmail.concat(e.target.value))
                    }
                  }}
                    onKeyDown={e => {
                      if (e.keyCode === 188 && e.target.value) {
                        setCcEmail(ccmail.concat(e.target.value))
                      }
                    }} {...params} type='email' variant="outlined" placeholder="Correos" size={'small'} />
                </>
              )}
            />
            <label className='labelStyle' htmlFor='asunto'>Asunto</label>
            <TextField style={{ backgroundColor: 'transparent', color: '#5f6273', marginBottom: 7, borderRadius: '1rem' }} fullWidth id="asunto" value={asuntoMail + ` (${props.listSurplus.data[cont] ? props.listSurplus.data[cont].placa : ''}) - por ${razonReimpresion.name}`} size={'small'} variant="outlined" disabled />
            <Editor
              apiKey={'4kbnibmrsojzegoh7cxijw4b8sjui7oxr9o3qxq3okpmeq5b'}
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={templateText()}
              init={{
                height: 350,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | " +
                  "bold italic backcolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            />
            {emailAlert.length > 0 && <Alert severity={'info'}>
              {emailAlert.message}
            </Alert>}
          </DialogContent>
          {sended === false ?
            <DialogActions style={{ justifyContent: 'end', padding: '0.5rem 2rem' }}>
              <Button className="btnSecundary w-auto" onClick={() => toggleDialog(cont)} color="primary">
                Cancelar
              </Button>
              <Button className="btnPrimary w-auto" onClick={send} color="primary" autoFocus>
                Enviar
              </Button>
            </DialogActions>
            :
            <DialogActions style={{ justifyContent: 'end', padding: '0.5rem 2rem' }}>
              <Button className="btnSecundary w-auto" onClick={() => toggleDialog(cont)} color="primary">
                Cancelar
              </Button>
              <Button className="btnPrimary w-auto" autoFocus>
                {emailAlert.length === 0 ? 'Enviando...' : 'Enviado'}
              </Button>
            </DialogActions>}
        </> : emailAlert.length == 0 ? <DialogContent>
          <DialogTitle>{"Motivo por el cual está solicitando la renovación de la placa adhesiva"}</DialogTitle>

          <DialogActions>
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <ButtonBase className='ContainerButtonGroup'>
                  <Grid container direction='row'>
                    {props.reprintReasons.length > 0 && props.reprintReasons.map(transact => {
                      return (
                        <Grid key={transact.id} item xs={12} sm={4} container alignItems='center'>
                          <Grid item xs="auto" sm="auto" lg="auto">
                            <BlueRadio
                              checked={razonReimpresion != null && razonReimpresion.id == transact.id}
                              onChange={handleChangeTypeReimpresion}
                              value={transact.id}
                              name={`${transact.id}`}
                            />
                          </Grid>
                          <Grid item xs sm lg="auto">
                            <Typography className='fontAa' variant="caption" display="block" gutterBottom>{transact.name}</Typography>
                          </Grid>
                        </Grid>)
                    })}
                  </Grid>
                </ButtonBase>
              </Grid>
              <Grid item xs='auto'>
                <Button className="btnSecundary btnCancelar" onClick={() => toggleDialog(cont)}>
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </DialogContent> :
          <>
            <DialogTitle style={{ color: '#5f6273', fontWeight: '700', paddingBottom: 0, paddingLeft: '1rem' }}>
              {emailAlert.error ? 'Error al enviar correo' : 'Enviado correctamente'}
            </DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid item xs={12}>
                  <Typography className='labelFlex' style={emailAlert.error ? {} : { marginBottom: '1rem' }}>{emailAlert.message}</Typography>
                </Grid>
                {emailAlert.error ?
                  <Grid item container>
                    <Grid item>
                      <b className='labelFlex'>Detalle del error:</b>
                    </Grid>

                    <Grid item>
                      <Typography className='labelFlex' style={{ paddingLeft: '1rem' }} paragraph>
                        {emailAlert.messageError}
                      </Typography>
                    </Grid>
                  </Grid>
                  : null}
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Button className='btnPrimary' style={{ margin: 'auto' }} onClick={toggleDialog} autoFocus>
                    Aceptar
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </>
        }
      </Dialog>
    );
  };

  const handleClose3 = async () => {
    await updateUbication({ idAsset: props.assetSurplus.asset.id, surpId: props.assetSurplus.asset.surpId, complement: locationChs, idLocation: recordsLocation })
    setOpen3(false);
    setSaveHeadquarterChs(headquarterChs)
    setSaveLocationChs(locationChs)
  };

  const selectHeadQuarter = (event, inputValue) => {
    /*let structureHQ = [{
      hg: "Edificio Echeverry (BARBOSA - ANTIOQUIA)",
      records: [
        { name: "Piso 1" },
        { name: "Piso 2" },
        { name: "Piso 3" },
        { name: "Piso 4" },
        { name: "Piso 5" }
      ]
    },{
      hg: "Bunker (Bogotá D.C - Bogotá D.C)",
      records: [
        { name: "Bloque A - Piso 1" },
        { name: "Bloque A - Piso 2" },
        { name: "Bloque A - Piso 3" },
        { name: "Bloque A - Piso 4" },
        { name: "Bloque B - Piso 1" },
        { name: "Bloque B - Piso 2" },
        { name: "Bloque B - Piso 3" },
        { name: "Bloque B - Piso 4" },
        { name: "Bloque B - Piso 5" },
      ]
    },{
      hg: "Zona Industrial (Bogotá D.C - Bogotá D.C)",
      records: [
        { name: "Piso 1" },
        { name: "Piso 2" },
        { name: "Piso 3" }
      ]
    }];*/
    let structHQ = props.locations.filter(hqBD => hqBD.fullname == inputValue);
    if (structHQ.length > 0) {
      setRecordsLocation(structHQ[0].id);
      setHeadQuarterChs(inputValue);
    } else {
      setHeadQuarterChs('');
    }

  }
  const renderDialogLocation = () => {
    //console.log("Estos son las ubicaciones", props.locations);
    return (
      <Dialog open={open3} disableEnforceFocus aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Ubicación del bien </DialogTitle>
        <DialogContent>
          <Autocomplete
            id="searchHQ"
            freeSolo
            style={{ width: 400 }}
            open={listHeadQuarters}
            onOpen={() => {
              setListHeadQuarters(true);
            }}
            onClose={() => {
              setListHeadQuarters(false);
            }}
            loading={loading}
            options={props.locations.map((option) => option.fullname)}
            onInputChange={searchLocation}
            value={headquarterChs}
            onChange={selectHeadQuarter}
            renderInput={(params) => (
              <>
                <label className='labelUbication'>Sede</label>
                <TextField {...params} className='ubicacionInput' variant="outlined" style={{ background: '#f0f2fa', borderRadius: '2rem', padding: 0 }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }} />
              </>
            )}
          />
          <label className='labelUbication'>Ubicación: </label>
          <TextField className='ubicacionInput' style={{ background: '#f0f2fa', borderRadius: '2rem' }}
            fullWidth
            value={locationChs}
            onChange={e => setLocationChs(e.target.value)}
            variant="outlined"
            type="text"
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button className="btnSecundary w-auto" onClick={() => {
            setHeadQuarterChs(saveHeadquarterChs)
            setLocationChs(saveLocationChs)
            setOpen3(false)
          }} disabled={headquarterChs == null} color="primary">
            Cancelar
          </Button>
          <Button className="btnPrimary w-auto" onClick={handleClose3} 
             disabled={headquarterChs == null} color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>);
  }

  const searchLocation = (event, inputValue) => {
    let params = {
      texto: inputValue
    };
    props.dispatch(fetchLocations(params));
  }

  const handleChangeSwitch2 = () => {
    setOpen3(true);
  };
  window.addEventListener("resize", () => {
    if ((window.innerHeight - 160) != heightWindow) {
      setHeightWindow(window.innerHeight - 160);
    }
  })

  return (
    <>
      <h1 className="tittleGris" style={{ margin: '0.5rem 0' }}>SOBRANTES REPORTADOS</h1>
      {props.listSurplus.data.length > 0 ? renderDialog() : null}
      {props.listSurplus.data.length > 0 && props.listSurplus.isFetching === false ? (

     <InfiniteScroll
          dataLength={props.listSurplus.data.length}
          next={() => fetchMore()}
          className="InfiniteScroll"
          hasMore={props.listSurplus.hasMore}
          height={heightWindow}
          loader={
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress className="circular-progress" />
            </div>
          }
        >
          {props.listSurplus.data.map((bien, cont) => {
            return (
              <Paper key={bien.id} className='papperSurplus'>
                <Grid container className='contenedorAssetGridMayor'>
                  <Grid className='contenedorAssetGrid' item xs={12} sm={12} container>
                    <Grid className='columnaLogo' item xs sm lg xl style={{ justifyContent: 'center' }} container direction="column">
                      <IconButton className="asset_icon" >
                        <img
                          className={classes.img}
                          alt="Imagen Bien"
                          src={getURLAsset(bien.category_icon)}
                        />
                      </IconButton>
                      <Typography align="center" display="block" gutterBottom className='descripcionBien'> {bien.descripcionBien}</Typography>
                    </Grid>

                    <Grid className='columnaInfo' item xs={12} sm={10} lg={10} xl={10} container>
                      <Grid item xs={12} container className='contenedorMedio' direction='row'>
                        <Grid item xs={4} sm={2} lg={2} xl={1} container direction='column'>
                          <Grid item>
                            <Typography style={{ color: '#5f6273' }} variant="caption">
                              <b className='bAsset'> Placa: </b>
                            </Typography>
                          </Grid>

                          <Grid item>
                            <Typography style={{ color: '#5f6273' }} variant="caption">
                              <b className='bAsset'>Estado del bien: </b>
                            </Typography>
                          </Grid>

                          <IconButton onContextMenu={(e) => { toggleMenuEdit(e, bien.serieBien, bien.placa, cont) }}
                            onClick={(e) => { toggleMenuEdit(e, bien.serieBien, bien.placa, cont) }}
                            style={{ cursor: 'pointer', padding: '0', position: 'absolute', right: '1.5rem', top: '1.5rem' }}>
                            <FontAwesomeIcon style={{ height: '1.1rem', color: '#5f6273', fontSize: '1.813rem' }} icon={faEllipsisV} />
                          </IconButton>
                          <Menu
                            className="menuShadow"
                            keepMounted
                            open={state.mouseY !== null && state.cont == cont}
                            onClose={e => { toggleMenuEdit(e, bien.serieBien, bien.placa, cont) }}
                            anchorReference="anchorPosition"
                            anchorPosition={
                              state.mouseY !== null && state.mouseX !== null
                                ? { top: state.mouseY, left: state.mouseX }
                                : undefined
                            }
                          >
                            <MenuItem className="menuItemColor" onClick={updateOneSurplus}>Cambiar a tramitado</MenuItem>
                          </Menu>
                          <Typography
                            style={{ padding: '0', position: 'absolute', left: '1.5rem', top: '1.5rem' }}
                            variant="caption" gutterBottom className="textCards">
                            #{cont + 1}
                          </Typography>
                        </Grid>

                        <Grid item xs={8} sm lg xl container direction='column'>
                          <Grid item>
                            <Typography variant="caption" gutterBottom className="textCards">
                              {bien.placa}
                            </Typography>
                          </Grid>

                          <Grid item>
                            <Typography variant="caption" gutterBottom
                              style={{
                                color: bien.colorEstadoBien, border: `1px solid ${bien.colorEstadoBien}`,
                                borderRadius: '7px', fontSize: '0.688rem', padding: '0 5px', lineHeight: '1'
                              }}>
                              {bien.estadoBien}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid item xs={4} sm={2} lg={2} xl={1} container direction='column'>
                          <Grid item>
                            <Typography style={{ color: '#5f6273', lineHeight: '1' }} variant="caption" gutterBottom className={classes.text}>
                              <b className='bAsset'> Serie del bien: </b>
                            </Typography>
                          </Grid>

                          <Grid item>
                            <Typography style={{ color: '#5f6273' }} variant="caption" display="block" gutterBottom>
                              <b className='bAsset'>Responsable: </b>
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid item xs={8} sm lg xl container direction='column'>
                          <Grid item>
                            <Typography
                              className="textCards" variant="caption" gutterBottom>
                              {bien.serieBien}
                            </Typography>
                          </Grid>

                          <Grid item>
                            <Typography
                              gutterBottom
                              className="textCards" variant="caption"
                            >{bien.responsable}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} container>
                        <Grid item xs={4} sm={2} lg={2} xl={1}>
                          <Typography style={{ color: '#5f6273' }} variant="caption">
                            <b className='bAsset'>Dependencia: </b>
                          </Typography>
                        </Grid>

                        <Grid item xs sm>
                          <Typography className="textCards" variant="caption" gutterBottom>
                            {bien.descripcionDependencia}
                            {/* {bien.dependencia != null && bien.dependencia.length > 1 ? bien.dependencia : dependencys.find(i => i.id == bien.dependencia)?.title} */}
                            {/* {bien.dependencia != null && bien.dependencia.length > 1 ? bien.dependencia : dependencys.find(i => i.id == bien.descripcionDependencia)?.title} */}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} container>
                        <Grid item xs={4} sm={2} lg={2} xl={1}>
                          <Typography style={{ color: '#5f6273' }} variant="caption" display="block" gutterBottom>
                            <b className='bAsset'>Seccional:</b>
                          </Typography>
                        </Grid>

                        <Grid item xs sm>
                          <Typography
                            variant="caption"
                            className="textCards"
                          >{bien.seccional}</Typography>
                        </Grid>
                      </Grid>

                      <Grid item xs container>
                        <Grid item xs={12} sm lg direction="row" container alignItems="center">
                          <Grid item xs={4} xl={2}>
                            <Typography style={{ color: '#5f6273' }} variant="caption" display="block" gutterBottom>
                              <b className='bAsset'>Reportado por: </b>
                            </Typography>
                          </Grid>

                          <Grid item xs xl>
                            <Typography className="textCards" variant="caption" gutterBottom>
                              {bien.reportName}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm lg direction="row" container alignItems="center">
                          <Grid item xs={4} xl={2}>
                            <Typography style={{ color: '#5f6273' }} variant="caption" display="block" gutterBottom>
                              <b className='bAsset'>Fecha de reporte: </b>
                            </Typography>
                          </Grid>

                          <Grid item xs xl>
                            <Typography className="textCards" variant="caption" gutterBottom>
                              {new Date(bien.report_date).toLocaleDateString("es-ES", configDate)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <hr className='hrStyle' />

                      <Grid item xs={12} sm container direction='row'>
                        <Grid item xs={4} sm={2} lg={2} xl={1} justifyContent='space-evenly' container direction='column'>
                          <Grid item xs>
                            <Typography style={{ color: '#5f6273' }} variant="caption" display="block" gutterBottom>
                              <b className='bAsset'>Estado sobrante: </b>
                            </Typography>
                          </Grid>
                          <Grid item xs>
                            <Typography style={{ color: '#5f6273' }} variant="body2" color="textSecondary" className={classes.text}>
                              <b className='bAsset'>Ubicación: </b>
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid item xs={8} sm lg xl justifyContent='space-evenly' container direction='column'>
                          <Grid item xs>
                            <Typography variant="caption" display="block"
                              gutterBottom
                              className="textCards">
                              <Button className="cardStateSurplus" style={bien.estado != 'Tramitado' ?
                                { backgroundColor: '#FAC73B' } : { backgroundColor: '#46CB4C' }}
                                size='small' onClick={() => { }}>
                                {bien.estado}
                              </Button>
                            </Typography>
                          </Grid>

                          <Grid item xs container>
                            <Grid item>
                              <Typography className="textCards" variant="caption" gutterBottom>
                                {/* {headquarterChs != null && headquarterChs.length > 0 ? <>
                                  {headquarterChs}<br />
                                </>
                                  : null} */}
                                {getnameLocation(bien.idLocation) + ' - '}{bien.descLocation != null && bien.descLocation}
                              </Typography>
                            </Grid>

                            <Grid item>
                              <Button className='btnEditar1'
                                onClick={handleChangeSwitch2} color='primary'>
                                Editar
                                <EditIcon style={{ fontSize: "0.688rem" }} />
                              </Button>
                              <Button style={{ marginLeft: '0.2rem' }} className='btnEditar2'
                                onClick={() => { handleChangeSwitch2(); console.log(open); }} color='primary'>
                                <EditIcon style={{ fontSize: "0.988rem" }} />
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

{/*                     <Grid style={{ textAlign: 'end' }} item xs={12}>
                      <Button onClick={() => toggleDialog(cont)}
                        style={{ width: 'auto' }}
                        className='btnEnviar btnEnviar1'
                        startIcon={<AlternateEmailIcon />}>Enviar correo electrónico - Reimpresión Placa
                      </Button>
                      <Button onClick={() => toggleDialog(cont)}
                        style={{ width: 'auto', lineHeight: '1' }}
                        className='btnEnviar btnEnviar2'
                        startIcon={<AlternateEmailIcon />}>Enviar correo electrónico
                      </Button>
                    </Grid> */}
                  </Grid>
                </Grid>
              </Paper>
            )
          })
          }
        </InfiniteScroll >
      ) : props.listSurplus.isFetching === false && props.listSurplus.data.length === 0 ? (
        <div className="container" style={{ padding: "20px" }}>
          <Alert severity="info">
            <AlertTitle>Info</AlertTitle>
            No hay resultados para la busqueda.
          </Alert>
        </div>
      ) : props.listSurplus.page == 1 && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress className="circular-progress" />
        </div>
      )}
    </>
  )

}

const mapStateToProps = (state) => ({
  listSurplus: state.listSurplus,
  assetSurplus: state.assetSurplus,
  historyList: state.inventoryHistory,
  params: state.getActualParams,
  withoutFillingOuts: state.withoutFillingOut,
  user: state.user,
  assignedAssets: state.assignedAssets,
  locations: state.locations.locations,
  assetsStatus: state.SUSILists.assetsStatus,
  changesTypes: state.SUSILists.changesTypes,
  missingReasons: state.SUSILists.missingReasons,
  reprintReasons: state.SUSILists.reprintReasons,
  sectionalsUser: state.sectionals,
  stages: state.stages,
  SyncSIAF: state.syncSIAF.isFetching,
});
export default connect(mapStateToProps, null, null, { forwardRef: true })(
  AssetlistSurplus
);

