import { FETCH_ASSIGNED_ASSETS_REQUEST, FETCH_ASSIGNED_ASSETS_SUCCESS, FETCH_ASSIGNED_ASSETS_ERROR } from "../types";

const initialState = {
    verificationLimit: 0,
    count: 0,
    page: 1,
    hasMore: true,
    assets: [],
    mount: true,
    isFetching: false,
    error: null
}

export default function assignedAssets(state = initialState, action) {
    switch (action.type) {
        case FETCH_ASSIGNED_ASSETS_REQUEST:
            return {
                ...state,
                page: 1,
                hasMore: true,
                isFetching: true
            };
        case FETCH_ASSIGNED_ASSETS_SUCCESS:
            return {
                ...state,
                page: action.payload.page,
                count: action.payload.count,
                isFetching: false,
                verificationLimit: action.payload.verificationLimit,
                hasMore: action.payload.assets.length === 20,
                assets: action.payload.page > 1 ? state.assets.concat(action.payload.assets) : action.payload.assets,
                countSeccional: action.payload.countSeccional,
                mount: action.payload.mount
            };
        case FETCH_ASSIGNED_ASSETS_ERROR:
            return {
                ...state,
                page: 1,
                hasMore: false,
                isFetching: false,
                error: action.payload.error,
                mount: action.payload.mount
            };
        default:
            return state;
    }
}