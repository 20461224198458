import { combineReducers } from "redux";
import assignedAssets from "./assignedAssetsReducer";
import assetsCategories from "./assetsCategoriesReducer";
import SUSILists from "./SUSIListsReducer";
import user from "./userReducer";
import locations from "./locationsReducer";
import withoutFillingOut from "./withoutFillingOutReducer";
import sectionals from "./sectionalsReducer";
import stages from './assetStageReducer';
import syncSIAF from "./syncWithSIAFReducer";
import getActualParams from './paramsSearchReducer';
import inventoryHistory from './inventoryHistoryReducer';
import callNotifys from './notificationsReducer'
import assetSurplus from "./assetSurplusReducer";
import listSurplus from "./surplusListReducer";
import surplusSearched from "./surplusSearched";
import getUserToken from "./loginSessionReducer";
import missingReducer from "./missingReducer.js";
import reportMissingReducer from "./reportMissingReducer";
import filesReducer from "./filesReducer";
import dataUserReducer from "./dataUserReducer";

export default combineReducers({
    assignedAssets, assetsCategories, user, SUSILists, locations, withoutFillingOut, sectionals, stages, syncSIAF, 
    getActualParams, inventoryHistory, callNotifys, assetSurplus, listSurplus, surplusSearched, getUserToken, 
    missingReducer, reportMissingReducer, filesReducer, dataUserReducer
})