import axios from "axios";
import { FETCH_REPORT_MISSINGS_ERROR, FETCH_REPORT_MISSINGS_REQUEST, FETCH_REPORT_MISSINGS_SUCCESS, UPDATE_REPORT_MISSINGS_ERROR, UPDATE_REPORT_MISSINGS_REQUEST, UPDATE_REPORT_MISSINGS_SUCCESS } from "../types";
import { getNotyByState } from "./notificationsActions";


export const fetchReportMissings = (params, mount = false) => (dispatch) => {
    dispatch({ type: FETCH_REPORT_MISSINGS_REQUEST, payload: { mount: mount } })
    let paramsStrings = "";
    for (const param in params) {
        if (mount && params['page'] != 1) {
            params['page'] = 1
        }
        if (paramsStrings.length > 0) {
            paramsStrings += "&";
        }
        paramsStrings += param + "=" + params[param];
    }

    axios.get(process.env.REACT_APP_BACKEND_URL + '/assignedAssets/ReportMissing?' + paramsStrings).then(data => {
        dispatch({
            type: FETCH_REPORT_MISSINGS_SUCCESS,
            payload: {
                missings: data.data.data,
                novedades: data.data.novedades,
                page: data.data.page,
                total: data.data.total,
                documents: data.data.documents
            }
        })
    }).catch(e => {
        console.error('Error al obtener faltantes reportados: ', e);
        dispatch({
            type: FETCH_REPORT_MISSINGS_ERROR,
            payload: {
                error: e
            }
        })
    })
}

export const aprovedMissing = (id, state, observationState, numero_documento) => (dispatch) => {
    dispatch({ type: UPDATE_REPORT_MISSINGS_REQUEST })

    axios.put(process.env.REACT_APP_BACKEND_URL + '/assignedAssets/' + id, { state, observationState }).then(data => {
        dispatch({ type: UPDATE_REPORT_MISSINGS_SUCCESS })
        dispatch(getNotyByState(id, state, numero_documento))

    }).catch(e => {
        console.error('Error al aprobar faltante: ', e);
        dispatch({
            type: UPDATE_REPORT_MISSINGS_ERROR,
            payload: {
                error: e
            }
        })
    })
}