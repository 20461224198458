import { FETCH_SYNC_SIAF_ERROR, FETCH_SYNC_SIAF_SUCCESS, FETCH_SYNC_SIAF_REQUEST, FETCH_BLOCK_REQUEST, FETCH_BLOCK_SUCCESS, FETCH_BLOCK_ERROR } from '../types/index';

import axios from 'axios';

export const fetchSyncWithSIAF = (params) => (dispatch) => {
    let paramsStrings = "";
    for (const param in params) {
        if (paramsStrings.length > 0) {
            paramsStrings += "&";
        }
        paramsStrings += param + "=" + params[param];
    }
    dispatch({ type: FETCH_SYNC_SIAF_REQUEST });
    axios.get(process.env.REACT_APP_BACKEND_URL + '/SIAF?' + paramsStrings).then(resp => {
        // console.log("respuesta servicio SIAF");
        // console.log(resp);
        dispatch({
            type: FETCH_BLOCK_SUCCESS,
            payload: {
                syncRepeated: resp.data.syncRepeated != undefined ? resp.data.syncRepeated : 0,
                message: resp.data.message,
                syncFail: resp.data.syncFail != undefined ? resp.data.syncFail : 0
            }
        })
        dispatch({
            type: FETCH_SYNC_SIAF_SUCCESS,
            payload: {
                sync: resp.data.ok,
                message: resp.data.message
            }
        })
    }).catch(e => {
        console.error('Error al sincronizar con SIAF: ', e);
        dispatch({
            type: FETCH_SYNC_SIAF_ERROR,
            payload: {
                sync: false,
                message: e.toString()
            }
        })
    })
}

export const fetchBlockWithSIAF = (params) => (dispatch) => {
    dispatch({ type: FETCH_BLOCK_REQUEST });
    axios.get(process.env.REACT_APP_BACKEND_URL + '/SIAF/assetsrepeat?user_document=' + params).then(resp => {
        if (resp.data.data.length > 0) {
            dispatch({
                type: FETCH_BLOCK_SUCCESS,
                payload: {
                    sync: resp.data.ok,
                    syncRepeated: resp.data.data[0].USER_BLOCKED_DUPLICATES,
                    syncFail: resp.data.data[0].USER_BLOCKED_VIEW_SIAF,
                }
            })
        } else {
            dispatch({
                type: FETCH_BLOCK_ERROR,
                payload: {
                    sync: resp.data.ok,
                    message: resp.data.message,
                    syncRepeated: 0
                }
            })
        }
    }).catch(e => {
        console.log(e.toString());
        dispatch({
            type: FETCH_SYNC_SIAF_ERROR,
            payload: {
                sync: false,
                message: e.toString()
            }
        })
    })
}