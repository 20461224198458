import axios from 'axios';
import qs from 'querystring';

export const updateUbication = async (params) => {
    const requestBody = {
        idLocation: params.idLocation,
        complemento: params.complement
    }
    /* const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    } */
    
    await axios.get(process.env.REACT_APP_BACKEND_URL + `/locations`).then(resp => {
        return resp;
    }).catch(e => {
        console.error('Error al obtener ubicaciones: ',e);
        return e;
    })

    return await axios.put(process.env.REACT_APP_BACKEND_URL + `/locations/${params.idAsset}`, qs.stringify(requestBody)).then(resp => {
        return resp;
    }).catch(e => {
        console.error('Error al obtener ubicaciones por Id: ',e);
        return e;
    })
}