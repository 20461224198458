import { FETCH_MISSINGS_ERROR, FETCH_MISSINGS_REQUEST, FETCH_MISSINGS_SUCCESS, FETCH_TOTAL_MISSINGS_ERROR, FETCH_TOTAL_MISSINGS_REQUEST, FETCH_TOTAL_MISSINGS_SUCCESS, UPDATE_MISSINGS_ERROR, UPDATE_MISSINGS_REQUEST, UPDATE_MISSINGS_SUCCESS } from "../types"

import axios from 'axios';

export const fetchMissings = (params, mount = false) => (dispatch) => {

    dispatch({ type: FETCH_MISSINGS_REQUEST, payload: { mount: mount } })
    let paramsStrings = "";
    for (const param in params) {
        if (mount && params['page'] != 1) {
            params['page'] = 1
        }
        if (paramsStrings.length > 0) {
            paramsStrings += "&";
        }
        paramsStrings += param + "=" + params[param];
    }

    axios.get(process.env.REACT_APP_BACKEND_URL + '/assignedAssets/Missing?' + paramsStrings).then(data => {
        dispatch({
            type: FETCH_MISSINGS_SUCCESS,
            payload: {
                missings: data.data.data,
                count: data.data.novedades,
                page: data.data.page,
                documents: data.data.documents
            }
        })
    }).catch(e => {
        console.error('Error al obtener faltantes: ', e);
        dispatch({
            type: FETCH_MISSINGS_ERROR,
            payload: {
                error: e
            }
        })
    })
}

export const fetchTotalMissings = (identification) => (dispatch) => {
    dispatch({ type: FETCH_TOTAL_MISSINGS_REQUEST })
    axios.get(process.env.REACT_APP_BACKEND_URL + '/assignedAssets/Missing?identification=' + identification).then(data => {

        dispatch({
            type: FETCH_TOTAL_MISSINGS_SUCCESS,
            payload: {
                total: data.data.data,
            }
        })
    }).catch(e => {
        console.error('Error al obtener el total de faltantes: ', e);
        dispatch({
            type: FETCH_TOTAL_MISSINGS_ERROR,
            payload: {
                error: e
            }
        })
    })
}

export const updateMissing = (typeTransact, asset, root = false) => (dispatch) => {

    dispatch({ type: UPDATE_MISSINGS_REQUEST })
    axios.put(process.env.REACT_APP_BACKEND_URL + `/assignedAssets/${asset}`, { typeTransact, root }).then(data => {
        dispatch({ type: UPDATE_MISSINGS_SUCCESS })
    }).catch(e => {
        console.error('Error al actualizar faltante: ', e);
        dispatch({
            type: UPDATE_MISSINGS_ERROR,
            payload: {
                errorstate: e
            }
        })
    })
}