import { faSortAmountDownAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  List, ListItem, ListItemText, Dialog, DialogTitle, DialogContent, DialogActions, RadioGroup, FormControlLabel,
  Radio, Button, TextField, Grid, withStyles, CircularProgress
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from "@material-ui/core/Box";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from '@material-ui/icons/Search';
import { Alert, AlertTitle } from "@material-ui/lab";
import React from "react";
import { connect } from "react-redux";
import { fetchInventoryHistory } from "../../redux/actions/inventoryHistoryAtions";
import "../../assets/css/online-inventory.css";
import { getCertificate } from "../../redux/actions/assignedAssetActions";
import { fetchAssetsStatus, fetchChangesTypes, fetchMissingReasons, getIdOptionSUSIList, getNameOptionSUSIList } from "../../redux/actions/SUSIListsActions";
import { DocumentService } from "../../services/generateDocumentService";
import { pdf } from "@react-pdf/renderer";
import { fetchAssetStages } from "../../redux/actions/assetStagesActions";
import { fetchLocations } from "../../redux/actions/locationsActions";
import { fetchSectionalsUser } from "../../redux/actions/sectionalsActions";
import { fetchAllNotifications } from "../../redux/actions/notificationsActions";

const BlueRadio = withStyles({
  root: {
    color: '#9d9ea5',
    '&$checked': {
      color: '#6ce1f5',
    }
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

class InventoryHistory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      textSearch: "",
      openDialogSort: false,
      openDialogFilter: false,
      valueSort: 'ASC',
      valueFilter: '',
      optionSelected: null,
      filterApplied: false,
      paramsFetchSectionals: {
        user_susi_id: props.user.user_susi_id,
      },
      clean: false
    };
  }

  componentDidMount() {
    this.getInformation();
    //this.props.dispatch(fetchAssignedAssets(this.state.paramsFetchAssets));
    this.props.dispatch(fetchAssetsStatus());
    this.props.dispatch(fetchChangesTypes());
    this.props.dispatch(fetchAssetStages());
    this.props.dispatch(fetchMissingReasons());
    this.props.dispatch(fetchLocations());
    this.props.dispatch(fetchSectionalsUser(this.state.paramsFetchSectionals));
    this.props.dispatch(fetchAllNotifications({ user: this.props.user.user_susi_id, page: 1 }));
  }

  getnameLocation(idLocation) {
    let dataname = this.props.locations.filter(location => {
      if (location.id == idLocation) {
        return location
      }
    });

    if (dataname.length > 0) {
      return dataname[0].fullname;
    }
  }

  async downloadPdf(year = '') {
    let data = await getCertificate(this.props.user.numero_documento, year);
    if (data.length > 0) {
      var docInventario = await pdf(DocumentService.generateDocument(data.map((asset) => {
        let novedad = asset.change_type_id == getIdOptionSUSIList('Faltante', this.props.changesTypes) ? ('FALTANTE (' + getNameOptionSUSIList(asset.missing_reason_id, this.props.missingReasons) + ')').toUpperCase() : asset.change_type_id == getIdOptionSUSIList('Verificado', this.props.changesTypes) ? 'VERIFICADO' : 'SIN NOVEDAD';
        let map = {
          //id: asset.id,
          serialBien: asset.serialBien,
          serieBien: asset.serieBien,
          placaBien: asset.placaBien,
          descripcionBien: asset.descripcionBien,
          estadoBien: this.props.stages.stages.find(stage => stage.code == asset.estadoBien).name,
          assetStatus: asset.estadoBienSUSI === getIdOptionSUSIList('Regular', this.props.assetsStatus),
          novedad: novedad,
          ubicacion: this.getnameLocation(asset.location_id),
          dependencia: asset.dependencia,
          seccional: asset.nombreSeccional,
          dateCertified: asset.date_checklist,
          funcionarioSolicitante: asset.funcionarioSolicitante,
          responsable: this.props.user.fullName
        }
        return map;
      }), true)).toBlob();
      var csvURL = window.URL.createObjectURL(docInventario);
      let tempLink = window.document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', 'FGN VERIFICACIÓN ' + year + '.pdf');
      // console.log('intenta descargar')
      tempLink.click();
    }
  }

  getInformation = () => {
    const params = {
      user_susi_id: this.props.user.numero_documento,
      order: this.state.valueSort,
      vigencia: this.state.valueFilter
    };
    this.props.dispatch(fetchInventoryHistory(params));
  }

  appliedFilter = () => {
    this.getInformation();
    this.setState({ openDialogSort: false, openDialogFilter: false });
    this.state.valueSort != 'ASC' ? this.setState({ filterApplied: true }) : this.setState({ filterApplied: false })
  };

  dialogSort = () => {
    const currentState = this.state.openDialogSort;
    this.setState({ openDialogSort: !currentState });
  };

  dialogFilter = () => {
    const currentState = this.state.openDialogFilter;
    this.setState({ openDialogFilter: !currentState });
  };

  onChangeAutoComplete = (event, input) => {
    if (input != null && input.year) {
      this.setState({ valueFilter: input.year, optionSelected: input }, this.getInformation)

    } else {
      this.setState({ valueFilter: '', optionSelected: input }, this.getInformation)
    }
  }

  handleClearOrders = () => {
    this.setState({ openDialogSort: false, openDialogFilter: false, valueSort: 'ASC', filterApplied: false, clean: true });
  };

  componentDidUpdate() {
    if (this.state.clean) {
      this.setState({ clean: false });
      this.appliedFilter()
    }
  }

  render() {
    return (
      <div>
        <h5 style={{ fontSize: '1.375rem', fontWeight: '800', color: '#5f6273' }}>REPORTES DE VERIFICACIÓN DE INVENTARIO INDIVIDUAL DE BIENES</h5>
        <div className="main-container" style={{ marginLeft: '0', paddingLeft: '0' }}>
          <Grid container className='ContenedorHistorial' alignItems="center" justifyContent="space-evenly">
            <Grid item xs={1} style={{ paddingLeft: '0.7rem' }}>
              <SearchIcon style={{ color: '#5f6273' }} />
            </Grid>
            <Grid item xs={10}>
              <Autocomplete
                className="complete"
                style={{ width: '100%', height: '2.25rem', color: '#5f6273' }}
                freeSolo
                value={this.state.optionSelected}
                options={this.props.historyList.autoInfo}
                getOptionLabel={option => option.label}
                onChange={this.onChangeAutoComplete}
                renderInput={(params) => <TextField fullWidth variant="outlined" {...params} placeholder="Vigencia de inventario" />}
              />
            </Grid>
          </Grid>

          <Box>
            <IconButton onClick={this.dialogSort}>
              <Badge overlap="rectangular" color="secondary">
                <FontAwesomeIcon style={{ color: this.state.filterApplied ? '#3f51b5' : '#828490' }} icon={faSortAmountDownAlt} />
              </Badge>
            </IconButton>
            <Dialog className='dialogReounded DialogOrdenar' id="inventoryHistory"
              open={this.state.openDialogSort}
              maxWidth="md"
            >
              <DialogTitle style={{ color: '#5F6273', fontSize: '1.25rem', fontWeight: '800' }}>Ordenar</DialogTitle>
              <DialogContent id="DialogOrdenar">
                <RadioGroup className="ordenarHistorial" aria-label="position" name="position" defaultValue="ASC" value={this.state.valueSort} onChange={e => this.setState({ valueSort: e.target.value })}/*  defaultValue={this.state.valueSort} */>
                  <FormControlLabel value="ASC" control={<BlueRadio color="primary" />} label="Ascendente" />
                  <FormControlLabel value="DESC" control={<BlueRadio color='secondary' />} label="Descendente" />
                </RadioGroup>
              </DialogContent>
              <DialogActions style={{ padding: '1rem' }}>
                <Button className="btnSecundary w-auto" onClick={this.dialogSort}>
                  Cancelar
                </Button>
                <Button className="btnSecundary w-auto" onClick={this.handleClearOrders} color="primary">
                  Limpiar
                </Button>
                <Button className="btnPrimary w-auto" onClick={this.appliedFilter} color="primary" autoFocus>
                  Aplicar
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </div>
        {this.props.historyList.isFetching ?
          <div style={{ textAlign: 'center', marginTop: '1rem' }}>
            <h4 className="sincronizandoTittle">Obteniendo historial...</h4>
            <CircularProgress className="circular-progress" />
          </div>
          : this.props.historyList.history.length > 0 ? (
            <List component="nav">
              {this.props.historyList.history.map((item, cont) => (
                <ListItem key={cont} button onClick={() => this.downloadPdf(`${new Date(item.dateAt).getFullYear()}`)}>
                  <ListItemText className="verificacionLink"
                    primary={
                      "Inventario vigencia " +
                      new Date(item.dateAt).getFullYear()
                    }
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              No se econtraron verficaciones anteriores.
            </Alert>
          )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  historyList: state.inventoryHistory,
  params: state.getActualParams,
  withoutFillingOuts: state.withoutFillingOut,
  user: state.user,
  assignedAssets: state.assignedAssets,
  locations: state.locations.locations,
  assetsStatus: state.SUSILists.assetsStatus,
  changesTypes: state.SUSILists.changesTypes,
  missingReasons: state.SUSILists.missingReasons,
  sectionalsUser: state.sectionals,
  stages: state.stages,
  SyncSIAF: state.syncSIAF.isFetching,
})
export default connect(mapStateToProps, null, null, { forwardRef: true })(
  InventoryHistory
);
