import React, { useEffect } from "react";
import { connect } from "react-redux";
import "../../App.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faFilter, faSortAmountDownAlt } from "@fortawesome/free-solid-svg-icons";

import {
  FormControl, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, withStyles, DialogActions, DialogContent, IconButton,
  TextField, Button, Select, Dialog, Badge
} from "@material-ui/core";

import AutoCompleteMissings from "../../components/autocomplete/AutoCompleteMissings";
import ReportMissingAssetsList from "./ReportMissingAssetsList";
import { DocumentService } from "../../services/generateDocumentService";
import { pdf } from "@react-pdf/renderer";
import { getptionSUSIList } from "../../redux/actions/SUSIListsActions";

const BlueRadio = withStyles({
  root: {
    color: '#9d9ea5',
    '&$checked': {
      color: '#6ce1f5',
    }
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);


const ReportMissingInventory = ({ listSectionals, changesTypes, assetsStatus, params }) => {
  const listValues = React.useRef(null);

  const [orderApplied, setOrderApplied] = React.useState(false);
  const [filterApplied, setFilterApplied] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openOrders, setOpenOrders] = React.useState(false);
  const [chxOrder, setChxOrder] = React.useState('orderPlaca');
  const [orders, setOrders] = React.useState({
    orderDescripcion: "ASC",
    orderPlaca: "ASC",
    orderSerie: "ASC",
    orderRegional: "ASC",
  });
  const [clean, setclean] = React.useState(false)
  const [seccional, setSeccional] = React.useState("");
  const [dependencia, setDependencia] = React.useState("");
  const [Serie, setSerie] = React.useState("");
  const [novedad, setNovedad] = React.useState("");
  const [estado, setEstado] = React.useState("");
  const [descripcionBien, setDescripcionBien] = React.useState("");
  const [placa, setPlaca] = React.useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOrdersClickOpen = () => {
    setOpenOrders(true);
  };

  const handleOrdersClose = () => {
    setOpenOrders(false);
  };

  const handleOrders = (e) => {
    setOrders({ ...orders, [e.target.name]: e.target.value });
  };

  const handleChxChange = (event) => {
    setChxOrder(event.target.value);
  };

  const handleAplication = () => {
    let ordersArr = [
      chxOrder == "orderDescripcion" ? orders.orderDescripcion : "",
      chxOrder == "orderPlaca" ? orders.orderPlaca : "",
      chxOrder == "orderSerie" ? orders.orderSerie : "",
      chxOrder == "orderRegional" ? orders.orderRegional : "",
    ];

    if (chxOrder.length > 0) {
      setOrderApplied(true);
    } else {
      setOrderApplied(false);
    }

    if (ordersArr[0].length > 0 || (ordersArr[1].length > 0 && ordersArr[1] != "ASC") || ordersArr[2].length > 0 || ordersArr[3].length > 0) {
      setOrderApplied(true);
    } else {
      setOrderApplied(false);
    }

    if (seccional.length > 0 || dependencia.length > 0 || estado.toString().length > 0 || descripcionBien.length > 0 || placa.length > 0 || novedad.toString().length > 0 || Serie.length > 0) {
      setFilterApplied(true)
    } else {
      setFilterApplied(false)
    }

    setOpenOrders(false);
    setOpen(false);

    listValues.current.updateParamsAssets(
      seccional,
      dependencia,
      estado,
      descripcionBien,
      placa,
      novedad,
      Serie,
      ordersArr
    );
  };

  const handleClearFilter = () => {
    setSeccional('');
    setEstado('');
    setDependencia('');
    setDescripcionBien('');
    setNovedad('');
    setSerie('');
    setPlaca('');
  };

  const handleClearOrders = () => {
    setclean(true)
    setChxOrder('orderPlaca')
    setOrders({ ...orders, orderPlaca: "ASC" })
  }

  useEffect(() => {
    if (clean) {
      setclean(false)

      setOpenOrders(false);
      setOrderApplied(false);
      handleAplication()
    }
  }, [clean])

  // equivalent to componentDidMount
  return (
    <Grid container alignItems="center" style={{ margin: '0' }} justifyContent='flex-end'>
      <Grid item xs={12} sm={6}>
        <p className="tittleBienes">BIENES NO VERIFICADOS FÍSICAMENTE</p>
      </Grid>

      <Grid item xs="auto" sm={6} container alignItems="center" justifyContent="flex-end">
        <Grid item xs={10} sm={10} lg={6} xl={4}>
          <AutoCompleteMissings total={true} style={{ backgroundColor: '#f0f2fa' }} />
        </Grid>

        <Grid item xs={1} style={{ textAlign: 'center' }}>
          <IconButton style={{ padding: 0 }} color={orderApplied === true ? 'primary' : 'inherit'} onClick={handleOrdersClickOpen}>
            <Badge color={orderApplied ? 'primary' : 'secondary'} overlap="rectangular">
              <FontAwesomeIcon style={{ color: orderApplied ? 'inherit' : '#828490' }} icon={faSortAmountDownAlt} />
            </Badge>
          </IconButton>
          <Dialog className='dialogReounded DialogOrdenar'
            open={openOrders}
            fullWidth
            maxWidth="sm"
            aria-labelledby="form-dialog-title"
          >
            <DialogContent>
              <Grid item xs={12}>
                <p className="tittleOrden">Ordenar</p>
              </Grid>
              <Grid id="DialogOrdenar" container>
                <RadioGroup
                  row
                  aria-label="position"
                  name="chxOrder"
                  value={chxOrder}
                  onChange={handleChxChange}
                  defaultValue="end"
                >
                  <Grid style={{ marginTop: '1rem' }} container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={<BlueRadio />}
                        label="Descripción"
                        labelPlacement="end"
                        value='orderDescripcion'
                      />
                    </Grid>
                    {chxOrder == 'orderDescripcion' ? (
                      <Grid item xs={6}>
                        <RadioGroup
                          row
                          aria-label="position"
                          name="orderDescripcion"
                          value={orders.orderDescripcion}
                          onChange={handleOrders}
                          defaultValue="end"
                        >
                          <FormControlLabel
                            className="orden1"
                            value="ASC"
                            control={<BlueRadio />}
                            label="Ascendente"
                            labelPlacement="top"
                          />
                          <FormControlLabel
                            className="orden1"
                            value="DESC"
                            control={<BlueRadio />}
                            label="Descendente"
                            labelPlacement="top"
                          />

                          <FormControlLabel
                            className="orden2"
                            value="ASC"
                            control={<BlueRadio />}
                            label="Asc"
                            labelPlacement="top"
                          />
                          <FormControlLabel
                            className="orden2"
                            value="DESC"
                            control={<BlueRadio />}
                            label="Desc"
                            labelPlacement="top"
                          />
                        </RadioGroup>
                      </Grid>
                    ) : null}
                  </Grid>
                  <br />
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6} sm={6} lg={6} xl={6}>
                      <FormControlLabel
                        control={<BlueRadio />}
                        label="Placa"
                        labelPlacement="end"
                        value='orderPlaca'
                      />
                    </Grid>
                    {chxOrder == 'orderPlaca' ? (
                      <Grid item xs={6} sm={6} lg={6} xl={6}>
                        <RadioGroup
                          row
                          aria-label="position"
                          name="orderPlaca"
                          value={orders.orderPlaca}
                          onChange={handleOrders}
                          defaultValue="end"
                        >
                          <FormControlLabel
                            className="orden1"
                            value="ASC"
                            control={<BlueRadio />}
                            label="Ascendente"
                            labelPlacement="top"
                          />
                          <FormControlLabel
                            className="orden1"
                            value="DESC"
                            control={<BlueRadio />}
                            label="Descendente"
                            labelPlacement="top"
                          />

                          <FormControlLabel
                            className="orden2"
                            value="ASC"
                            control={<BlueRadio />}
                            label="Asc"
                            labelPlacement="top"
                          />
                          <FormControlLabel
                            className="orden2"
                            value="DESC"
                            control={<BlueRadio />}
                            label="Desc"
                            labelPlacement="top"
                          />
                        </RadioGroup>
                      </Grid>
                    ) : null}
                  </Grid>
                  <br />
                  <Grid container spacing={2} justifyContent='space-between' alignItems="center">
                    <Grid item xs={6} sm={6} lg={6} xl={6}>
                      <FormControlLabel
                        control={<BlueRadio />}
                        label="Serie"
                        labelPlacement="end"
                        value='orderSerie'
                      />
                    </Grid>
                    {chxOrder == 'orderSerie' ? (
                      <Grid item xs={6} sm={6}>
                        <RadioGroup
                          row
                          aria-label="position"
                          name="orderSerie"
                          value={orders.orderSerie}
                          onChange={handleOrders}
                          defaultValue="end"
                        >
                          <FormControlLabel
                            className="orden1"
                            value="ASC"
                            control={<BlueRadio />}
                            label="Ascendente"
                            labelPlacement="top"
                          />
                          <FormControlLabel
                            className="orden1"
                            value="DESC"
                            control={<BlueRadio />}
                            label="Descendente"
                            labelPlacement="top"
                          />

                          <FormControlLabel
                            className="orden2"
                            value="ASC"
                            control={<BlueRadio />}
                            label="Asc"
                            labelPlacement="top"
                          />
                          <FormControlLabel
                            className="orden2"
                            value="DESC"
                            control={<BlueRadio />}
                            label="Desc"
                            labelPlacement="top"
                          />
                        </RadioGroup>
                      </Grid>
                    ) : null}
                  </Grid>

                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={<BlueRadio />}
                        label="Regional"
                        labelPlacement="end"
                        value='orderRegional'
                      />
                    </Grid>
                    {chxOrder == 'orderRegional' ? (
                      <Grid item xs={6}>
                        <RadioGroup
                          row
                          aria-label="position"
                          name="orderRegional"
                          value={orders.orderRegional}
                          onChange={handleOrders}
                          defaultValue="end"
                        >
                          <FormControlLabel
                            className="orden1"
                            value="ASC"
                            control={<BlueRadio />}
                            label="Ascendente"
                            labelPlacement="top"
                          />
                          <FormControlLabel
                            className="orden1"
                            value="DESC"
                            control={<BlueRadio />}
                            label="Descendente"
                            labelPlacement="top"
                          />

                          <FormControlLabel
                            className="orden2"
                            value="ASC"
                            control={<BlueRadio />}
                            label="Asc"
                            labelPlacement="top"
                          />
                          <FormControlLabel
                            className="orden2"
                            value="DESC"
                            control={<BlueRadio />}
                            label="Desc"
                            labelPlacement="top"
                          />
                        </RadioGroup>
                      </Grid>
                    ) : null}
                  </Grid>
                </RadioGroup>
              </Grid>
            </DialogContent>
            <DialogActions className="PadDialogActions">
              <Button className="btnSecundary w-auto" onClick={handleOrdersClose}>
                Cancelar
              </Button>
              <Button className="btnSecundary w-auto" onClick={handleClearOrders} color="primary">
                Limpiar
              </Button>
              <Button className="btnPrimary w-auto" onClick={handleAplication} color="primary" autoFocus>
                Aplicar
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>

        <Grid item xs={1} style={{ textAlign: 'center' }}>
          <IconButton style={{ padding: 0 }} color={filterApplied ? 'primary' : 'inherit'} onClick={handleClickOpen}>
            <Badge color={filterApplied ? 'primary' : 'secondary'} overlap="rectangular">
              <FontAwesomeIcon style={{ color: filterApplied ? 'inherit' : '#828490' }} icon={faFilter} />
            </Badge>
          </IconButton>
          <Dialog className='dialogFiltros dialogReounded'
            open={open}
            fullWidth
            maxWidth="sm"
            aria-labelledby="form-dialog-title"
          >
            <DialogContent id="Filtros">
              <div style={{ padding: '0.5rem' }}>
                <Grid container spacing={1} >
                  <div style={{ color: '#5f6273', fontWeight: '600', fontSize: '1.25rem', padding: '0 0 0.5rem 0' }}>Filtros</div>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <label className='labelModal' htmlFor="lbel-seccional">Seccional</label>
                      <Select
                        className="select-filtros"
                        value={seccional}
                        onChange={(e) => setSeccional(e.target.value)}

                        id="outlined-basic-1"
                        labelId="lbel-seccional"
                        label="Seccional"
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem className="menuItemColor" value="">
                          <em>Elija una seccional</em>
                        </MenuItem>
                        {listSectionals.sectionals.map((data, cont) => (
                          <MenuItem className="menuItemColor" key={data.id} value={data.name}>{data.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <label className='labelModal' htmlFor="Dependencia">Dependencia</label>
                    <TextField
                      className="select-filtros"
                      value={dependencia}
                      onChange={(e) => setDependencia(e.target.value)}

                      id="Dependencia"
                      variant="outlined"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <label className='labelModal' htmlFor="lbel-estadobien">Estado bien</label>
                      <Select
                        className="select-filtros"
                        value={estado}
                        onChange={(e) => setEstado(e.target.value)}

                        id="outlined-basic-3"
                        labelId="lbel-estadobien"
                        label="Estado bien"
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem className="menuItemColor" value="">
                          <em>Elija un estado</em>
                        </MenuItem>
                        {assetsStatus.length > 0 && assetsStatus.map((item, count) => (
                          count != 2 &&
                          <MenuItem className="menuItemColor" key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <label className='labelModal' htmlFor="lbel-novedad">Novedad</label>
                      <Select
                        className="select-filtros"
                        value={novedad}
                        onChange={(e) => setNovedad(e.target.value)}

                        id="outlined-basic-3"
                        labelId="lbel-novedad"
                        label="Novedad"
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem className="menuItemColor" value="">
                          <em>Elija una novedad</em>
                        </MenuItem>
                        {changesTypes.length > 0 && changesTypes.map((item, count) => (
                          <MenuItem className="menuItemColor" key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))
                        }
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <label className='labelModal' htmlFor="DescripciondelBien">Descripción del Bien</label>
                    <TextField
                      className="select-filtros"
                      value={descripcionBien}
                      onChange={(e) => setDescripcionBien(e.target.value)}

                      id="DescripciondelBien"
                      variant="outlined"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <label className='labelModal' htmlFor="Placa">Placa</label>
                    <TextField
                      className="select-filtros"
                      value={placa}
                      onChange={(e) => setPlaca(e.target.value)}

                      id="Placa"
                      variant="outlined"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <label className='labelModal' htmlFor="Dependencia">Serie</label>
                    <TextField
                      className="select-filtros"
                      value={Serie}
                      onChange={(e) => setSerie(e.target.value)}
                      id="Serie"
                      variant="outlined"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </div>
            </DialogContent>
            <DialogActions style={{ padding: '0 2rem 1.5rem 1rem' }}>
              <Button className="btnSecundary w-auto" onClick={handleClose} color="primary">
                Cancelar
              </Button>
              <Button className="btnSecundary w-auto" onClick={handleClearFilter} color="primary">
                Limpiar
              </Button>
              <Button className="btnPrimary w-auto" onClick={handleAplication} color="primary" autoFocus>
                Aplicar
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>

        <FontAwesomeIcon icon={faFilePdf} size="2x" onClick={async () => {
          const formatNumber = num => String(num).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          const formatNumberPoint = num => String(num).replace(/\B(?=(\d{3})+(?!\d)+(?=.\b))/g, ',');

          listValues.current.props.reportMissings.reportMissings.map((reportMissing, cont) => {

            for (const key in reportMissing) {
              if (Object.hasOwnProperty.call(reportMissing, key)) {
                switch (key) {
                  case 'stateWitout':
                    let state = getptionSUSIList(reportMissing[key], listValues.current.props.susiList.replacementStatus)
                    reportMissing['Estado'] = state.name
                    break;

                  case 'novedadId':
                    let novedad = getptionSUSIList(reportMissing[key], listValues.current.props.susiList.missingReasons)
                    reportMissing['Tipo de novedad'] = novedad.name
                    break;

                  case 'typeOfProcedure':
                    let procedure = getptionSUSIList(reportMissing[key], listValues.current.props.susiList.typeTransact)
                    reportMissing['Tipo solicitud'] = procedure.name
                    break;

                  case 'value':
                    reportMissing['Valor'] = `$${reportMissing[key] != null ? `${reportMissing[key]}`.includes('.') ?
                      formatNumberPoint(reportMissing[key]) : formatNumber(reportMissing[key]) : 0}`
                    break;

                  case 'datefiled':
                    reportMissing['Fecha radicación'] = new Intl.DateTimeFormat('es-ES', { dateStyle: 'long' }).format(new Date(reportMissing[key]))
                    break;

                  case 'description':
                    reportMissing['descripción'] = reportMissing[key]
                    break;

                  case 'serieBien':
                    reportMissing['Serie del bien'] = reportMissing[key]
                    break;

                  case 'responsableEmail':
                    reportMissing['Email del responsable'] = reportMissing[key] ? reportMissing[key] : 'No posee email registrado'
                    break;

                  case 'observations':
                    reportMissing['Observaciones faltante'] = reportMissing[key]
                    break;

                  case 'observationsAproved':
                    reportMissing['Observaciones aprobación'] = reportMissing[key]
                    break;

                  case 'docs':
                    reportMissing[key].forEach((doc) => {
                      let docType = getptionSUSIList(doc.REDO_DOCUMENT_TYPE_ID, listValues.current.props.susiList.typeDcoument)

                      if (typeof doc.REDO_DOCUMENT_TYPE_ID == 'number') {
                        doc.REDO_DOCUMENT_TYPE_ID = docType.name
                      }
                      if (doc.REPO_PATH.includes('uploads/replacements/')) {
                        doc.REPO_PATH = doc.REPO_PATH.replace('uploads/replacements/', '')
                      }
                    })
                    break;

                  default:
                    break;
                }
              }
            }
          })
          
          var data = {
            reportMissings: listValues.current.props.reportMissings.reportMissings,
            docsReport: listValues.current.props.susiList.typeDcoument,
            susiList: listValues.current.props.susiList,
            responsable: listValues.current.props.user.fullName,
            seccional: '',
            departamento: '',
            municipio: ''
          }
          var docInventario = await pdf(DocumentService.generateDocument(data, false, false, true)).toBlob();
          var csvURL = window.URL.createObjectURL(docInventario);
          let tempLink = window.document.createElement('a');
          tempLink.href = csvURL;
          tempLink.setAttribute('download', 'FGN REPORTE DE INVENTARIO FÍSICO - NOVEDAD: FALTANTES.pdf');
          tempLink.click();

        }} style={{ color: '#828490', cursor: 'pointer' }} />
      </Grid>

      <ReportMissingAssetsList ref={listValues} />
    </Grid>
  );
};

function mapStateToProps(state) {
  return {
    listSectionals: state.sectionals,
    listStages: state.stages,
    changesTypes: state.SUSILists.changesTypes,
    assetsStatus: state.SUSILists.assetsStatus,
    params: state.getActualParams,
    typeDcoument: state.SUSILists.typeDcoument,
    user: state.user,
  };
}
export default connect(mapStateToProps)(ReportMissingInventory);
