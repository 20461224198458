import React, { useState } from 'react';
import useStyles from '../../assets/jss/online-inventory/online-inventory';
import {
  Paper, Grid, Typography, IconButton, ButtonBase, Radio, withStyles, Dialog, DialogContent, DialogActions, Button,
  Tooltip
} from '@material-ui/core';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import faCopy from '../../assets/img/iconos/FontAwsome-copy.svg';
import faFilePdf from '../../assets/img/iconos/FontAwsome (file-pdf).svg';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getNameOptionSUSIList, getptionSUSIList } from '../../redux/actions/SUSIListsActions';
import { useEffect } from 'react';
import { deleteFilesWithout, postFilesWithout } from '../../redux/actions/filesActios';
import { updateMissing } from "../../redux/actions/missingActions";
import { file_size_MB, susi_lists } from '../../config';
import { Alert } from '@material-ui/lab';

const BlueRadio = withStyles({
  root: {
    color: '#9d9ea5',
    '&$checked': {
      color: '#6ce1f5'
    },
    padding: 0
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const WithoutAsset = (props) => {
  const classes = useStyles();

  const [changeType, setChangeType] = useState(props.data.typeOfProcedure);
  const [stateWitout, setStateWitout] = useState(props.data.stateWitout);
  const [showDialogTypeMissing, setShowDialogTypeMissing] = useState(false)

  const [typeDocs, setTypeDocs] = useState([]);
  const [docs, setDocs] = useState([]);
  const [deleteDocs, setDeleteDocs] = useState([]);
  const [postDocs, setPostDocs] = useState([]);
  const [docsError, setDocsError] = useState([]);

  const configDate = { year: 'numeric', month: 'long', day: 'numeric' };

  const handleChangeType = (event) => {
    let optionData = getptionSUSIList(event.target.value, props.propsDad.susiList.typeTransact)

    if (stateWitout.id == susi_lists.replacements_states.without_management_id) {
      setChangeType(optionData);

      props.propsDad.dispatch(updateMissing(event.target.value, props.data.id))
      setDocs([])
    }
  };

  const closeDialogTypeMissing = (change, state) => {
    if (!change) {
      let docsList = []

      typeDocs.map((typeDoc) => {
        if (props.data.docs.length > 0 && postDocs.length == 0) {
          let docExist = props.data.docs.map(docDb => {
            if (docDb.REDO_DOCUMENT_TYPE_ID == typeDoc.id)
              return docDb
          })

          docExist.map((docE) => {
            if (docE && docE.REDO_DOCUMENT_TYPE_ID == typeDoc.id) {
              let encode = docE.REPO_PATH.split('\\')[2]
              docE.name = encode

              docsList.push({ [`${typeDoc.id}`]: docE })
            }
          })
        } else if (postDocs.length > 0) {
          postDocs.map((docE) => {
            if (docE[`${typeDoc.id}`]) {
              docsList.push(docE)
            }
          })
        }
      })

      setDocs(docsList)
    } else {
      let nameDocumentUpload = []

      let documentUpload = docs.filter(docFilter => {
        return typeDocs.map(type => {
          if (docFilter[`${type.id}`]) {
            nameDocumentUpload.push(type.id)
            return docFilter[`${type.id}`]
          }
        })
      })

      let nameDocumentDelete = []

      let documentDelete = deleteDocs.filter(docFilter => {
        return typeDocs.map(type => {
          if (docFilter[`${type.id}`]) {
            nameDocumentDelete.push(type.id)
            return docFilter[`${type.id}`]
          }
        })
      })

      setPostDocs(documentUpload)
      props.propsDad.dispatch(postFilesWithout(documentUpload, nameDocumentUpload, props.data.id))
      if (deleteDocs.length > 0) {
        props.propsDad.dispatch(deleteFilesWithout(deleteDocs, nameDocumentDelete))
      }
    }

    setShowDialogTypeMissing(false);
    setDocsError([])
    setDeleteDocs([])
  };

  const changeState = () => {
    /* pasar id radicado */
    let state = getptionSUSIList(susi_lists.replacements_states.based_id, props.propsDad.susiList.replacementStatus)
    setStateWitout(state)
    props.data.datefiled = new Date()

    props.propsDad.dispatch(updateMissing(changeType.id, props.data.id, state.id))
  }

  const renderDialogDocuments = () => {
//    console.log(props)
    let razonFaltante = getNameOptionSUSIList(props.data.novedadId, props.propsDad.missingReasons)

    return (
      <Dialog className='dialogReounded dialogDocuments' open={showDialogTypeMissing} aria-labelledby="form-dialog-title">
        <DialogContent style={{ borderRadius: '1rem' }}>
  

          {changeType!= null ? <>
          <p className='tituloCards'>{changeType != null && changeType.name}</p>
          <Typography variant="caption">
                    <p className='uploadItem'><span style={{ fontWeight: 'bold' }}>Apreciad@ servidor@</span> </p>
          </Typography>

          {changeType.value == 1 ? <>
                    <p className='uploadItem'>Al iniciar la reclamación por siniestro usted está declarando que la información reportada corresponde a la ocurrencia de un evento accidental, súbito e imprevisto, es decir, un evento ajeno a su voluntad, sobre el cual no tuvo control como responsable de la conservación y salvaguarda del bien.</p>
                    <p className='uploadItem'>Recuerde que es su deber “Responder por la conservación de los útiles, equipos, muebles y bienes confiados a su guarda o administración y rendir cuenta oportuna de su utilización”. Artículo 38, Código General Disciplinario.</p>
                </> : null}
          {changeType.value == 3 ? <>
                  <p className='uploadItem'>La reposición voluntaria corresponde a la reposición de un bien de iguales o superiores características, avalado por el área técnica competente, comprado en un establecimiento legalmente constituido, con factura que cumpla con los requisitos de Ley para que tenga la naturaleza de título valor y garantía legal.  </p>
                  <p className='uploadItem'>Antes de adquirirlo, solicite el aval técnico que indique que la cotización corresponde a un bien de similares o superiores características y que <span style={{ fontWeight: 'bold' }}>puede ser utilizado por la Entidad. </span></p>
                </> : null}
          {changeType.value == 2 ? <>
                  <p className='uploadItem'>El pago voluntario corresponde al pago del valor de reposición de un bien de similares o superiores características avalado por el área técnica competente. </p>
                  <p className='uploadItem'>Antes de realizar la consignación, solicite el aval técnico que indique que la cotización corresponde a un bien de similares o superiores características. </p>
                </> : null}



          </> : null}













              
          {typeDocs.map((docByType, index) => {
            return <Grid key={index} container direction='row' justifyContent='space-between' alignItems='center'>
              <Grid item xs={8} sm={9} lg={9}>
                <p className='uploadItem'>{docByType.name}</p>
              </Grid>
              <Grid item xs sm lg container justifyContent='flex-end'>
                <Grid item>
                  {docs.map(docStatePdf => {
                    if (docStatePdf[`${docByType.id}`]) {
                      return (
                        <Tooltip key={index} title={docStatePdf[docByType.id].name}>
                          <IconButton>
                            <img src={faFilePdf} alt='iconoPDF' />
                          </IconButton>
                        </Tooltip>
                      )
                    }
                  })}
                </Grid>
                {stateWitout.id !== susi_lists.replacements_states.based_id &&
                  stateWitout.id !== susi_lists.replacements_states.aproved_id ? <>
                  <Grid item style={{ alignSelf: 'center' }}>
                    <label htmlFor={index} className='uploadItem'>
                      <FontAwesomeIcon icon={faUpload} className='uploadIcon' />
                    </label>
                    <input className='uploadItem' name={docByType.name} id={index} disabled={susi_lists.replacements_states.based_id == stateWitout.id || susi_lists.replacements_states.aproved_id == stateWitout.id} type={'file'}
                      accept='application/pdf' multiple={false} onChange={(e) => {
                        let docsArray = []
                        const MAX_FILE_SIZE = props.propsDad.user.limit_file_size / file_size_MB / file_size_MB // 3MB
                        const filesize = e.target.files[0].size / file_size_MB / file_size_MB // Convierte byte a MB

                        docs.forEach(docState => {
                          if (!docState[`${docByType.id}`]) {
                            docsArray.push(docState)
                          }
                        })

                        if (filesize <= MAX_FILE_SIZE && e.target.files[0].type == 'application/pdf') {
                          let repeatSearch = docsArray.map(docArr => typeDocs.find(typeDoc => docArr[`${typeDoc.id}`] != undefined && e.target.files[0].name == docArr[`${typeDoc.id}`].name))
                          let repeatFind = repeatSearch.find(repeat => repeat != undefined)
                          let errors = []

                          if (repeatFind == undefined) {
                            docsArray.push({ [`${docByType.id}`]: e.target.files[0] })
                            setDocs(docsArray)

                            docsError.forEach(docError => {
                              if (docError.nameType != docByType.name) {
                                errors.push(docError)
                              }
                            })
                          } else {
                            docsError.forEach(docError => {
                              if (docError.nameType != docByType.name) {
                                errors.push(docError)
                              }
                            })

                            errors.push({ errorType: 'errorByRepeat', nameFile: e.target.files[0].name, nameType: docByType.name })
                          }

                          setDocsError(errors)
                        } else {
                          let errors = []
                          docsError.forEach(docError => {
                            if (docError.nameType != docByType.name) {
                              errors.push(docError)
                            }
                          })

                          if (filesize > MAX_FILE_SIZE) {
                            errors.push({ errorType: 'errorBySize', nameFile: e.target.files[0].name, nameType: docByType.name })
                          } else if (e.target.files[0].type !== 'application/pdf') {
                            errors.push({ errorType: 'errorByType', nameFile: e.target.files[0].name, nameType: docByType.name })
                          }

                          setDocsError(errors)
                        }
                      }} />
                  </Grid>
                  <Grid item style={{ alignSelf: 'center' }}>
                    {docs.map((docStateMinus, key) => {
                      let docId = docStateMinus[`${docByType.id}`]

                      if (docId) {
                        return <FontAwesomeIcon className='uploadIcon' key={key} style={{ cursor: 'pointer', fontSize: 'initial' }}
                          icon={faTrash} onClick={(e) => {
                            if (susi_lists.replacements_states.based_id !== stateWitout.id && susi_lists.replacements_states.aproved_id !== stateWitout.id) {
                              let docFilterDelete = docs.filter(docFilter => docFilter[`${docByType.id}`] == undefined);
                              let docDelete = docs.filter(docFilter => docFilter[`${docByType.id}`] !== undefined);
                              let docsDeleteState = deleteDocs;

                              docsDeleteState.push(docDelete[0])

                              setDeleteDocs(docsDeleteState)
                              setDocs(docFilterDelete)
                            }
                          }} />
                      }
                    })}
                  </Grid>
                </> : null}
              </Grid>
            </Grid>
          })}
          {docsError.length > 0 &&
            <Alert severity="error" id='Alert'>
              <ul>
                {docsError.map((docError, index) => {
                  if (docError.errorType == 'errorBySize') {
                    return <li key={index}>{docError.nameType}: Su archivo supera los 3MB permitidos</li>
                  } else if (docError.errorType == 'errorByType') {
                    return <li key={index}>{docError.nameType}: El tipo de documento es incorrecto</li>
                  } else if (docError.errorType == 'errorByRepeat') {
                    return <li key={index}>{docError.nameType}: El documento que intenta subir ya existe, porfavor cambie el nombre del archivo</li>
                  }
                })
                }
              </ul>
            </Alert>
          }
        </DialogContent>

        <DialogActions style={{ alignSelf: 'center', marginBottom: '1.938rem' }}>
          <Button onClick={() => closeDialogTypeMissing(false, props.data.typeOfProcedure)} style={{ width: '90px', padding: '10px 0' }} className='btnSecundary' color="primary">
            Cancelar
          </Button>
          <Button disabled={susi_lists.replacements_states.based_id == stateWitout.id || susi_lists.replacements_states.aproved_id == stateWitout.id}
            onClick={() => closeDialogTypeMissing(true, changeType)} style={{ width: '90px', padding: '10px 0' }} className='btnGuardar' color="primary">
            Guardar
          </Button>
          {typeDocs.length > 0 && docs && docs.length == typeDocs.length && susi_lists.replacements_states.without_management_id == stateWitout.id
            || typeDocs.length > 0 && docs && docs.length == typeDocs.length && susi_lists.replacements_states.rejected_id == stateWitout.id ? <Button onClick={() => {
              closeDialogTypeMissing(true, changeType)
              changeState()
            }}
              style={{ width: '80px', padding: '10px 0' }} className='btnPrimary' color="primary">
            Reportar
          </Button> : null}
        </DialogActions>
      </Dialog>
    );
  }
  const formatNumber = num => String(num).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const formatNumberPoint = num => String(num).replace(/\B(?=(\d{3})+(?!\d)+(?=.\b))/g, ',');

  useEffect(() => {
    let procedure = getptionSUSIList(props.data.typeOfProcedure, props.propsDad.susiList.typeTransact)
    let state = getptionSUSIList(props.data.stateWitout, props.propsDad.susiList.replacementStatus)
    let optionData = getptionSUSIList(changeType, props.propsDad.susiList.typeTransact)

    if (changeType == 'number') {
      setChangeType(optionData)
    }

    setStateWitout(state)
    setChangeType(procedure)
  }, [])

  useEffect(() => {
    let typeTansactDocs = props.propsDad.susiList.transactTypesDocuments.filter(docProp => {
      /* Obtiene la relación entre documentos y transacción */
      if (changeType != null && typeof changeType == 'object' && docProp.TRTD_TRANSACTION_TYPE_ID == changeType.id && docProp.TRTD_MIRE_ID == props.data.novedadId) {
        return docProp
      } else if (changeType != null && typeof changeType == 'number' && docProp.TRTD_TRANSACTION_TYPE_ID == props.data.typeOfProcedure && docProp.TRTD_MIRE_ID == props.data.novedadId) {
        return docProp
      }
    })

    let typeDcoument = props.propsDad.susiList.typeDcoument.filter(docProp => {
      //Obtiene los documentos que son necesarios según el tipo de transacción
      let find = typeTansactDocs.find(documentFind => documentFind.TRTD_DOCUMENT_ID == docProp.id)

      if (find)
        return docProp
    })

    setTypeDocs(typeDcoument)
  }, [changeType])

  useEffect(() => {
    let docsList = []

    typeDocs.map((typeDoc, index) => {
      if (props.data.docs) {
        let docExist = props.data.docs.map(docDb => {
          if (docDb.REDO_DOCUMENT_TYPE_ID == typeDoc.id)
            return docDb
        })

        docExist.map((docE) => {
          if (docE && docE.REDO_DOCUMENT_TYPE_ID == typeDoc.id) {
            let encode = docE.REPO_PATH.split('/')[2]
            docE.name = encode

            docsList.push({ [`${typeDoc.id}`]: docE })
          }
        })
      }
    })

    setDocs(docsList)
  }, [typeDocs])

  return (
    <div className={classes.root + ' contenedorAsset'} >
      <Paper variant="outlined" style={{
        padding: '1rem 0', borderRadius: '2rem', border: 0, margin: 0, maxWidth: 'none',
        boxShadow: '3px 3px 20px 0px #d6e0f4', position: 'relative'
      }}
        className={classes.paper}>
        <Grid container className='contenedorAssetGridMayor'>
          <Grid className='contenedorAssetGrid' item xs={12} sm={12} container>
            <Grid className='columnaLogo' item xs sm={3} lg={2} style={{ padding: '0 0.5rem' }} justifyContent='center' container direction="column">
              <IconButton className="asset_icon" >
                <img className={classes.img} alt="Imagen Bien" src={props.url_imagen} />
              </IconButton>
              <Typography align="center" variant="caption" display="block" gutterBottom className='descripcionBien'> {props.data.description}</Typography>
            </Grid>
            <Grid className='columnaInfo' item xs={12} sm={9} lg container>
              <Grid item xs container className='contenedorMedio'>
                <Grid item xs={12} container direction='row'>
                  <Grid item container xs={12}>
                    <Grid item xs={4} sm={2} lg={2} xl={2} container direction='column'>
                      <Grid item>
                        <Typography variant="caption">
                          <b className='bAsset'> Placa: </b>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography style={{ color: '#5f6273' }} variant="caption">
                          <b className='bAsset'>Serie del bien: </b>
                        </Typography>
                      </Grid>
                      <Grid item className='dependenciaXs'>
                        <Typography variant="caption">
                          <b className='bAsset'>Estado:</b>
                        </Typography>
                      </Grid>
                      <Grid item className='dependenciaLg'>
                        <Typography variant="caption">
                          <b className='bAsset'>Fecha radicación:</b>
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item xs={8} sm lg xl container direction='column' justifyContent='space-between'>
                      <Grid item>
                        <Typography variant="caption" className="textCards">
                          {props.data.placa}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography className="textCards" variant="caption">
                          {props.data.serie}
                        </Typography>
                      </Grid>
                      <Grid item className='dependenciaXs'>
                        <Typography variant="caption"
                          style={{
                            color: stateWitout.description, border: `0.1rem solid ${stateWitout.description}`,
                            borderRadius: '0.5rem', padding: '0.000001rem 0.4rem', fontSize: '0.813rem', fontWeight: '600'
                          }}>
                          {stateWitout.name}
                        </Typography>
                      </Grid>
                      <Grid item className='dependenciaLg'>
                        <Typography variant="caption" className="textCards">
                          {props.data.datefiled != null && new Date(props.data.datefiled).toLocaleDateString("es-ES", configDate) + ' ' + new Date(props.data.datefiled).toLocaleTimeString('es-ES', { hour12: true })}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item xs={4} sm={2} lg={2} xl={2} container direction='column'>
                      <Grid item className='dependenciaLg'>
                        <Typography variant="caption">
                          <b className='bAsset'>Estado:</b>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="caption">
                          <b className='bAsset'>Valor:</b>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="caption" className='dependenciaXs'>
                          <b className='bAsset'>Fecha radicación:</b>
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item xs={8} sm container direction='column'>
                      <Grid item className='dependenciaLg'>
                        <Typography variant="caption"
                          style={{
                            color: stateWitout.description, border: `0.1rem solid ${stateWitout.description}`,
                            borderRadius: '0.5rem', padding: '0.000001rem 0.4rem', fontSize: '0.813rem', fontWeight: '600'
                          }}>
                          {stateWitout.name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="caption" className="textCards">
                          ${props.data.value != null ? `${props.data.value}`.includes('.') ? formatNumberPoint(props.data.value) : formatNumber(props.data.value) : 0}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="caption" className="textCards dependenciaXs">
                          {props.data.datefiled != null ? new Date(props.data.datefiled).toLocaleDateString("es-ES", configDate) + ' ' + new Date(props.data.datefiled).toLocaleTimeString('es-ES', { hour12: true }) : 0}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {props.data.observations != null &&
                    <Grid item xs={12} container justifyContent='space-between'>
                      <Grid item xs={4} sm={2} lg={2} xl={2}>
                        <Typography variant="caption">
                          <b className='bAsset'>Observaciones faltante:</b>
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography className="textCards" variant="caption">
                          {props.data.observations}
                        </Typography>
                      </Grid>
                    </Grid>}

                  {props.data.observationsAproved != null &&
                    <Grid item xs={12} container justifyContent='space-between'>
                      <Grid item xs={4} sm={2} lg={2} xl={2}>
                        <Typography variant="caption">
                          <b className='bAsset'>Observaciones aprobación:</b>
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography className="textCards" variant="caption">
                          {props.data.observationsAproved}
                        </Typography>
                      </Grid>
                    </Grid>}
                </Grid>

                <hr className='hrStyle' />

                <Grid item xs={12} container alignItems='center'>
                  <Grid item xs={4} sm={2} lg={2} xl={2} className='tipoTramite'>
                    <Typography style={{ color: '#5f6273' }} variant="body2" color="textSecondary" className={classes.text}>
                      <b className='bAsset'>Tipo trámite: </b>
                    </Typography>
                  </Grid>

                  <Grid item xs={8} sm={10} lg={10} xl={10} container direction='column'>
                    <ButtonBase className='ContainerButtonGroup'>
                      <Grid container direction='row' justifyContent='space-between'>
                        {props.propsDad.susiList.typeTransact.length > 0 && props.propsDad.susiList.typeTransact.map(transact => {
                          return (
                            <div className='ButtonGroup'>
                                <BlueRadio
                                  checked={changeType != null && changeType.id == transact.id}
                                  onChange={handleChangeType}
                                  value={transact.id}
                                  name={`${transact.id}`}
                                />
                                <Typography className='fontAa' variant="caption" display="block" gutterBottom>{transact.name}</Typography>
                              
                              </div>)
                        })}
                      </Grid>
                    </ButtonBase>
                  </Grid>
                </Grid>

                <Grid item xs={12} container alignItems='center'>
                  <Grid item xs={4} sm={2} lg={2} xl={2}>
                    <Typography style={{ color: '#5f6273' }} variant="body2" color="textSecondary" className={classes.text}>
                      <b className='bAsset Documentos'>Documentos: </b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8} lg={9} xl={9} container alignItems='flex-start' className='cantidadDocumentos'>
                    <img src={faCopy} onClick={() => {
                      if (typeof changeType == 'object') {
                        setShowDialogTypeMissing(true)
                      }
                    }} style={{ cursor: 'pointer' }} />
                    <div style={{ padding: '0 0.5rem' }}>
                      {typeDocs.length > 0 ?
                        docs != undefined && docs.length == typeDocs.length ? // verde
                          <Typography className='fontAa' variant="caption" display="block" gutterBottom>
                            <CheckCircleOutlineIcon style={{ color: '#46CB4C', fontSize: '1.5rem', marginRight: '0.5rem' }} />
                            {docs.length} adjuntos - {typeDocs.length - docs.length} faltante{docs.length != 1 && 's'}
                          </Typography>
                          : props.data.docs && docs.length > 0 ? // amarillo
                            <Typography className='fontAa' variant="caption" display="block" gutterBottom>
                              <CheckCircleOutlineIcon style={{ color: '#EAB130', fontSize: '1.5rem', marginRight: '0.5rem' }} />
                              {docs.length} adjunto{docs.length != 1 && 's'} - {typeDocs.length - docs.length} faltante{docs.length != 1 && 's'}
                            </Typography>
                            : // rojo
                            <Typography className='fontAa' variant="caption" display="block" gutterBottom>
                              <CheckCircleOutlineIcon style={{ color: '#EF4682', fontSize: '1.5rem', marginRight: '0.5rem' }} />
                              {docs.length} adjuntos - {typeDocs.length - docs.length} faltante{docs.length != 1 && 's'}
                            </Typography>
                        : null
                      }
                    </div>
                  </Grid>
                  {stateWitout.id == susi_lists.replacements_states.without_management_id && typeDocs.length > 0 && docs && docs.length == typeDocs.length
                    || stateWitout.id == susi_lists.replacements_states.rejected_id && typeDocs.length > 0 && !showDialogTypeMissing && docs && docs.length == typeDocs.length ? <Grid item xs={12} sm={2} lg={1} xl={1} container alignItems='flex-start' className='cantidadDocumentos'>
                    <Button onClick={() => changeState()} style={{ width: '80px', padding: '5px 0' }} className='btnPrimary' color="primary">
                      Reportar
                    </Button>
                  </Grid> : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      {renderDialogDocuments()}
    </div >
  );
};

export default WithoutAsset