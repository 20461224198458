export const REACT_APP_VERSION = '1.0.0';
export const REACT_APP_BACKEND_URL = 'http://localhost/xamp';
export const RESOLUCION_INVENTARIO = '/assets/docs/RESOLUCION 01166 DFGN 29-07-2021 DEROGA RES. 0-0532 DE 2014.pdf';
export const susi_lists = {
    replacements_states: {
        without_management_id: 173521,
        aproved_id: 173523,
        rejected_id: 173524,
        based_id: 173522,
    },
    newness: {
        missing: 160693,
        checked: 160692
    }
}
export const file_size_MB = 1024