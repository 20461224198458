import React, { useEffect, useRef, useState } from 'react';
import useStyles from '../../assets/jss/online-inventory/online-inventory';
import {
  Paper, Grid, Typography, IconButton, ButtonBase,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
  TextField, Button, Switch, Box, Radio, withStyles, FormControl, RadioGroup,
  FormControlLabel, CircularProgress, List, ListItem, ListItemAvatar, ListItemText, Avatar, Tooltip, Chip, TextareaAutosize, styled
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ImageIcon from '@material-ui/icons/Image';
import CommentIcon from '@material-ui/icons/Comment';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';

import { updateAssignedAsset } from '../../redux/actions/assignedAssetActions';
import { getIdOptionSUSIList, getNameOptionSUSIList } from '../../redux/actions/SUSIListsActions';
import { updateUbication } from '../../redux/actions/ubicationsActions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import AutoComplete from '@material-ui/lab/Autocomplete';

import { Alert, Autocomplete } from '@material-ui/lab';

import { fetchLocations } from '../../redux/actions/locationsActions';
import { DocumentService } from '../../services/generateDocumentService';
import { pdf } from '@react-pdf/renderer';
import { Editor } from '@tinymce/tinymce-react';
import { sendMail } from '../../redux/actions/sendMailActions';
import Checkbox from '@material-ui/core/Checkbox';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { susi_lists } from '../../config';

const BlueRadio = withStyles({
  root: {
    color: '#9d9ea5',
    '&$checked': {
      color: '#6ce1f5'
    }
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const RedRadio = withStyles({
  root: {
    color: '#9d9ea5',
    '&$checked': {
      color: '#ef4682',
    }
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const MaterialUISwitch = styled(Switch)(() => ({
  margin: '0 0.5rem',
  '& .MuiSwitch-switchBase': {
    color: '#3f66c4',
    '&.Mui-checked': {
      color: '#ef4682'
    },
  },
}));

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 20,
  height: 20,
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : 'white',
  border: '2px solid #9d9ea5',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#fff',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: '100%',
    height: '100%',
    backgroundImage: "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3C!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc.--%3E%3Cpath d='M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7l233.4-233.3c12.5-12.5 32.8-12.5 45.3 0z' fill='%236ce1f5'/%3E%3C/svg%3E\")",
    content: '""',
  },
  border: '3px solid #6ce1f5',
});

function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        '&:hover': { bgcolor: 'transparent' },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ 'aria-label': 'Checkbox demo' }}
      {...props}
    />
  );
}


const AssignedAsset = (props) => {
  const classes = useStyles();
  let li = [];
  let assetStatusBD = "";
  if (props.data.estadoBienSUSI === getIdOptionSUSIList('Regular', props.assetsStatus)) {
    assetStatusBD = true;
  } else {
    assetStatusBD = false;
  }
  const [culminationDate, setculminationDate] = useState(new Date(`${props.user.culmination_date} / ${new Date().getFullYear()}`));
  const [assetStatus, setAssetStatus] = useState(assetStatusBD);
  const [assignedAssetID, setAssignedAssetID] = useState(props.data.id);
  const [showDialogStatusBad, setDialogStatusBad] = useState(false);
  const [showDialogShare, setShowDialogShare] = useState(false);
  const [alertObservations, setAlertObservations] = useState(true);

  const [changeType, setChangeType] = useState(props.data.change_type_id);
  const [missingReason, setMissingReason] = useState(props.data.missing_reason_id);
  const [errorFormMissing, setErrorFormMissing] = useState('');
  const [observations, setObservations] = useState(props.data.observations);
  const [savingMissingReason, setSavingMissingReason] = useState(false);
  const [recordsLocation, setRecordsLocation] = useState([]);
  const [listHeadQuarters, setListHeadQuarters] = useState(false);
  const loading = listHeadQuarters && props.locations.length === 0;
  const [headquarterChs, setHeadQuarterChs] = useState(props.ubication);
  const [locationChs, setLocationChs] = useState(props.data.location_complement);
  const [switchOption2, setSwitchOption2] = useState(false);
  const [showDialogTypeMissing, setShowDialogTypeMissing] = useState(false)
  const [textOption, setTextOption] = useState('')
  const [showDialogtheft, setShowDialogtheft] = useState(false)
  const [open3, setOpen3] = useState(false);
  const [openPrint, setOpenPrint] = useState(false);
  //const [isOut, setIsOut] = useState(props.isOut);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState(props.user.CORREO_REPORTAR_REIMPRESION_PLACA)
  const [ccmail, setCcEmail] = useState([])
  const [razonReimpresion, setRazonReimpresion] = useState(null)
  const [asuntoMail, setAsuntoMail] = useState(`Solicitud Renovación de plaqueta adhesiva (${props.data.placaBien})`)
  const [sended, setSended] = useState(false)
  const [enableFeaturesPrint, setEnableFeaturesPrint] = useState(false)
  const [emailAlert, setEmailAlert] = useState('')
  const editorRef = useRef(null);

  const [saveHeadquarterChs, setSaveHeadquarterChs] = useState(props.ubication);
  const [saveLocationChs, setSaveLocationChs] = useState(props.data.location_complement);
  //const [alertRuleNotify, setAlertRuleNotify] = useState(li.length);
  const [displayAlert, setDisplayAlert] = useState('none');

  const configDate = { year: 'numeric', month: 'long', day: 'numeric' };

  const toggleDialog = () => {

    const currentState = open;
    if (!open) {
      setSended(false);
      setEmailAlert('');
      setRazonReimpresion(null);
      setCcEmail([]);
    }
    setOpen(!currentState);

  };

  const toggleDialogPrint = () => {
    const currentState = openPrint;
    setOpenPrint(!currentState);
  };

  // Mensajes icono de alarma
  if (props.data.estadoBien == 'En tramite de reposición') {
    li.push('Responsabilidad en proceso');
  }
  if (changeType == getIdOptionSUSIList('Faltante', props.changesTypes)) {
    li.push('Novedad: Faltante');
  }
  if (assetStatus == true) {
    li.push('Estado del bien: Regular');
  }
  if (new Date(props.data.date_checklist).getFullYear() < new Date().getFullYear()) {
    li.push('Verificación vigente: sin reportar');
  }

  /**
   * Maneja el evento de hacer clic en el check de Estado
  */
  const handleChangeStatus = async () => {
    if (!assetStatus) {
      setDialogStatusBad(true);
    }
    if (assignedAssetID) {
      updateAssignedAsset({
        id: assignedAssetID,
        asset_status: (!assetStatus) ? getIdOptionSUSIList('Regular', props.assetsStatus) : getIdOptionSUSIList('Bueno', props.assetsStatus),
        change_type: changeType
      });
    } else {
      console.error('Se esta intentando actualizar un estado de un bien que no existe');
    }
    setAssetStatus(!assetStatus);
    props.getCountWithoutUpdate()
  };

  const downloadPdf = async () => {
    toggleDialogPrint()
    let novedad = changeType == getIdOptionSUSIList('Faltante', props.changesTypes) ? ('FALTANTE (' + getNameOptionSUSIList(missingReason, props.missingReasons) + ')').toUpperCase() : changeType == getIdOptionSUSIList('Verificado', props.changesTypes) ? 'VERIFICADO' : 'SIN NOVEDAD';
    var docInventario = await pdf(DocumentService.generateDocument([
      {
        serialBien: props.data.serialBien,
        serieBien: props.data.serieBien,
        placaBien: props.data.placaBien,
        descripcionBien: props.data.descripcionBien,
        estadoBien: props.data.estadoBien,
        assetStatus: assetStatus,
        novedad: novedad,
        ubicacion: headquarterChs,
        lastCheck: props.data.date_checklist,
        dependencia: props.data.dependencia,
        seccional: props.data.nombreSeccional,
        funcionarioSolicitante: props.data.funcionarioSolicitante,
        features: props.data.features,
        responsable: props.user.fullName
      }
    ], false, enableFeaturesPrint)).toBlob();
    var csvURL = window.URL.createObjectURL(docInventario);
    let tempLink = window.document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', 'FGN - ' + props.data.placaBien + '.pdf');
    tempLink.click();
  }

  const handleChangeType = async (event) => {
    if (event.target.value == getIdOptionSUSIList('Faltante', props.changesTypes)) {
      setShowDialogTypeMissing(true);
    }
    setChangeType(event.target.value);
    props.getCountWithoutUpdate()
    if (assignedAssetID && event.target.value == getIdOptionSUSIList('Verificado', props.changesTypes)) {
      updateAssignedAsset({
        id: assignedAssetID,
        assetStatus: (assetStatus) ? getIdOptionSUSIList('Regular', props.assetsStatus) : getIdOptionSUSIList('Bueno', props.assetsStatus),
        change_type: event.target.value
      });
    } else if (event.target.value != getIdOptionSUSIList('Faltante', props.changesTypes)) {
      console.error('Se esta intentando actualizar un estado de un bien que no existe');
    }
  };

  const handleMissingReason = (event) => {
    let nameSUSIList = getNameOptionSUSIList(event.target.value, props.missingReasons)
    props.getCountWithoutUpdate()
    setMissingReason(event.target.value);
    setErrorFormMissing("");
    if (nameSUSIList == 'Perdida') {
      setTextOption('Se considera la pérdida de un bien, cuando de forma voluntaria o involuntaria se ocasiona el extravió físico del mismo. Esta novedad debe ser comunicada al jefe inmediato, y de igual forma podrá iniciar la reclamación ante el seguro, reposición voluntaria o consignación ante el tesoro nacional.')
    } else {
      setTextOption('Se considera que un bien ha sido hurtado, cuando el mismo ha sido tomado de forma ilegítima sin su consentimiento por un tercero. Esta novedad debe ser comunicada al jefe inmediato, y de igual forma podrá iniciar la reclamación ante el seguro, reposición voluntaria o consignación ante el tesoro nacional.')
    }
    setShowDialogtheft(false) //TLA deshabilito 
    //updateAssignedAsset({id: assignedAssetID, missing_reason: event.target.value});
  };

  const handleChangeSwitch2 = () => {
    setSwitchOption2(!switchOption2);
    setOpen3(true);
    setDisplayAlert('none')
  };

  const handleClose = () => {
    props.getCountWithoutUpdate()
    setDialogStatusBad(false);
  };

  const closeDialogTypeMissing = () => {
    setAlertObservations(true)
    setShowDialogTypeMissing(false);
    setChangeType(props.data.change_type_id)
  };

  const notifyAlert = () => {
    if (li.length > 0) {
      return (
        <ul style={{ margin: 0, padding: 0 }}>
          {li.map((item, count) => {
            return (
              <li key={count}>{item}</li>
            )
          })}
        </ul>
      );
    } else {
      return ''
    }
  }

  const handleClose3 = async () => {
    //console.log('recordsLocation: ',recordsLocation,' headquarterChs: ',headquarterChs);
    if (recordsLocation != null && headquarterChs.length > 0) {
      await updateUbication({ idAsset: assignedAssetID, complement: locationChs, idLocation: recordsLocation })
      setOpen3(false);
      setSaveHeadquarterChs(headquarterChs)
      setSaveLocationChs(locationChs)
      props.getCountWithoutUpdate()
      setDisplayAlert('none')
    } else {
      setDisplayAlert('flex')
    }
  };

  const handleObservations = (event) => {
    setObservations(event.target.value)
  }

  const handleSaveReasonMissing = async () => {
    let threeWords = []
    if (observations != null) {
      threeWords = observations.trim().split(/(\s)/).filter(i => i.trim().length > 0);
    }
    if (!savingMissingReason) {
      if (!missingReason) {
        setErrorFormMissing("Debe seleccionar una razón de Faltante");
        return;
      } else if (!observations) {
        setErrorFormMissing("Debe ingresar observaciones");
        return;
      } else if (threeWords.length < 3) {
        setErrorFormMissing("Minimo debe tener 3 palabras");
        return;
      } else {
        setErrorFormMissing("");
      }

      const savedRecord = await updateAssignedAsset({
        id: assignedAssetID,
        change_type: susi_lists.newness.missing,
        missing_reason: missingReason,
        observations: observations
      });
      if (savedRecord) {
        setSavingMissingReason(false);
        setShowDialogTypeMissing(false);
      }
      props.getCountWithoutUpdate()
      setAlertObservations(true)
    }
  }

  /* const handleDialogShare = () => {
    setShowDialogShare(true);
  } */

  const validateEmailString = (input = '') => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(input)
  }

  const handleCloseDialogShare = () => {
    setShowDialogShare(false);
  }

  const searchLocation = (event, inputValue) => {
    let params = {
      texto: inputValue
    };
    props.propsDad.dispatch(fetchLocations(params));
  }

  const selectHeadQuarter = (event, inputValue) => {
    /*let structureHQ = [{
      hg: "Edificio Echeverry (BARBOSA - ANTIOQUIA)",
      records: [
        { name: "Piso 1" },
        { name: "Piso 2" },
        { name: "Piso 3" },
        { name: "Piso 4" },
        { name: "Piso 5" }
      ]
    },{
      hg: "Bunker (Bogotá D.C - Bogotá D.C)",
      records: [
        { name: "Bloque A - Piso 1" },
        { name: "Bloque A - Piso 2" },
        { name: "Bloque A - Piso 3" },
        { name: "Bloque A - Piso 4" },
        { name: "Bloque B - Piso 1" },
        { name: "Bloque B - Piso 2" },
        { name: "Bloque B - Piso 3" },
        { name: "Bloque B - Piso 4" },
        { name: "Bloque B - Piso 5" },
      ]
    },{
      hg: "Zona Industrial (Bogotá D.C - Bogotá D.C)",
      records: [
        { name: "Piso 1" },
        { name: "Piso 2" },
        { name: "Piso 3" }
      ]
    }];*/
    if (inputValue == null) inputValue = headquarterChs
    let structHQ = props.locations.filter(hqBD => hqBD.fullname == inputValue);
    if (structHQ.length > 0) {
      setRecordsLocation(structHQ[0].id);
      setHeadQuarterChs(inputValue);
    }
  }

  /* const defaultValueUbications = () => {
    let structHQ = props.locations.filter(hqBD => hqBD.id == props.data.location_id);
    if (structHQ.length > 0) {
      return structHQ[0].fullname;
    }
  }

  const selectLocation = (event, inputValue) => {
    setLocationChs(inputValue);
  } */

  /* renderDialog */

  const renderDialogPrint = () => {
    return (
      <div>
        <Dialog className='dialogReounded RoportInventory'
          fullWidth
          maxWidth={'sm'}
          open={openPrint}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle className='dialogTttle' id="alert-dialog-title">Reporte inventario</DialogTitle>
          <DialogContent style={{ paddingLeft: '2rem' }}>
            <DialogContentText id="alert-dialog-description">
              <FormControlLabel
                control={
                  <BpCheckbox
                    checked={enableFeaturesPrint}
                    onChange={e => setEnableFeaturesPrint(e.target.checked)}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Caracteristicas"
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ padding: '0.5rem 1rem 1rem' }}>
            <Button className='btnSecundary' onClick={toggleDialogPrint} color="primary">
              Cancelar
            </Button>
            <Button className='btnPrimary' onClick={downloadPdf} color="primary" autoFocus>
              Enviar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  const renderDialogShare = () => {
    return (
      <Dialog className='dialogReounded' open={showDialogShare}>
        <DialogTitle id="form-dialog-title">Compartir Bien</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Seleccione como quiere compartir el bien
          </DialogContentText>
          <List>
            <ListItem button onClick={() => alert("Vamos a compartir como imagen")} key="image">
              <ListItemAvatar>
                <Avatar>
                  <ImageIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Como Imagen" />
            </ListItem>
            <ListItem button onClick={() => alert("Vamos a compartir como HTML")} key="html">
              <ListItemAvatar>
                <Avatar>
                  <CommentIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Como HTML" />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogShare} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleCloseDialogShare} color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  const renderDialogStatusBad = () => {
    return (<Dialog className='dialogReounded estadoBien'
      open={showDialogStatusBad}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      style={{ padding: '1rem', borderRadius: '20px!important', boxShadow: '0!important' }}
    >
      <DialogContent style={{ padding: '40px 39px 15px' }}>
        <DialogContentText className='dialogText' id="alert-dialog-slide-description">
          {"Apreciad@ servidor@:"}
        </DialogContentText>
        <DialogContentText className='dialogText'  id="alert-dialog-slide-description">
        Seleccione la opción “regular” en caso de que el bien presente deterioro físico, pero 
        aún está en condición de prestar servicio.
        </DialogContentText>
        <DialogContentText className='dialogText' id="alert-dialog-slide-description">
        En caso de que el bien presente fallas o daños debe gestionar el trámite de conformidad
        con los numeral 5.1. “Defectos o fallas durante el uso o funcionamiento de los bienes” o
        5.2. “Ocurrencia de eventos accidentales, súbitos e imprevistos”, según corresponda, 
        del “Procedimiento para reportar y registrar daños, pérdidas, hurtos o faltantes de bienes
        devolutivos” (FGN-AP04-P-10)
        </DialogContentText>        
        <DialogContentText className='dialogText' id="alert-dialog-slide-description">
        En caso de que no requiera el bien para sus labores, debe gestionar el reintegro al almacén
        de acuerdo con el numeral 5.3. “Reintegro de bienes devolutivos al Almacén” del “Procedimiento
         para verificar y actualizar el registro del inventario individual de bienes devolutivos al servicio (FGN-AP04-P-17)”.
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ alignSelf: 'center', marginBottom: '0.938rem' }}>
        <Button className='btnPrimary' variant='outlined' onClick={handleClose}>
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>);
  }

  const renderDialogChangeTypeLost = () => {
    return (
      <Dialog className='dialogReounded dialogChangeLost' open={showDialogTypeMissing} aria-labelledby="form-dialog-title">
        <DialogContent style={{ borderRadius: '1rem' }}>
          <p className='tituloCards'>Apreciad@ servidor@</p>
          <DialogContentText className='dialogText'  id="alert-dialog-slide-description">
          Si el bien no está en su poder y no tiene control sobre él, seleccione aquí la razón de la ausencia.
          </DialogContentText>
          <DialogContentText className='dialogText'  id="alert-dialog-slide-description">
          Cuando finalice el reporte de su inventario, podrá dirigirse al módulo Bienes <span style={{ fontWeight: 'bold' }}>No Verificados Físicamente</span> para reportar la ausencia del bien.
          </DialogContentText>

          <FormControl component="fieldset" style={{ width: '100%' }}>
            <Grid style={{ paddingLeft: '1rem' }} direction="column" container>
              <Grid item container>
                <RadioGroup aria-label="missing_reason" name="missing_reason" value={missingReason} onChange={handleMissingReason}>
                  {props.missingReasons.map((optMissingReason) => (
                    <FormControlLabel
                      key={optMissingReason.value}
                      value={optMissingReason.id}
                      control={<BlueRadio />}
                      checked={missingReason == optMissingReason.id}
                      label={optMissingReason.name} />
                  ))}
                </RadioGroup>
              </Grid>
              <Grid item container direction="column">
                <Grid item container>
                  <label style={{ fontSize: '0.875rem', color: '#5f6273' }}>Observaciones</label>
                  <div style={{ color: 'red', paddingLeft: '0.2rem' }}>{' *'}</div>
                </Grid>
                <Grid item container>
                  <TextareaAutosize
                    onClick={() => {
                      if (alertObservations) {
                        setAlertObservations(false)
                        setTextOption('Indique de forma concreta la razón de la pérdida o hurto del bien a relacionar dentro de estas novedades.')
                        setShowDialogtheft(true)
                      }
                    }}
                    minRows={6}
                    cols={5}
                    autoFocus
                    id="name"
                    type="text"
                    value={observations == null ? ' ' : observations}
                    onChange={handleObservations}
                    className='textArea' />
                </Grid>
              </Grid>
            </Grid>
          </FormControl>
          {errorFormMissing != '' ? (<Alert style={{ marginTop: '0.5rem' }} severity="error">{errorFormMissing}</Alert>) : null}

          <DialogContentText className='dialogText'  id="alert-dialog-slide-description">
          <span style={{ fontWeight: 'bold' }}>Nota:</span>  No aplica para los bienes que asignó a otro servidor temporalmente en calidad de préstamo. Artículo 32. Resolución 01166 de 2021.
          </DialogContentText>

        </DialogContent>
        <DialogActions style={{ alignSelf: 'center', marginBottom: '1.938rem' }}>
          <Button onClick={closeDialogTypeMissing} style={{ width: '100px' }} className='btnSecundary' color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSaveReasonMissing} style={{ width: '100px' }} className='btnPrimary' color="primary">
            {savingMissingReason ? "Guardando ..." : "Aceptar"}
          </Button>
        </DialogActions>
      </Dialog>);
  }

  const renderDialogLocation = () => {
    //console.log("Estos son las ubicaciones", props.locations);
    return (
      <Dialog className='dialogReounded dialogUbication' open={open3} aria-labelledby="form-dialog-title">
        <DialogContent style={{ paddingBottom: '0 !important' }}>
          <p style={{ fontWeight: 'bold', fontSize: '1.25rem', color: '#5f6273', margin: 0, marginTop: '0rem' }}>Ubicación del bien</p>
          <AutoComplete
            id="searchHQ"
            freeSolo
            open={listHeadQuarters}
            onOpen={() => setListHeadQuarters(true)}
            onClose={() => setListHeadQuarters(false)}
            loading={loading}
            options={props.locations.map((option) => {
              return option.fullname
            })}
            onInputChange={searchLocation}
            value={headquarterChs}
            onChange={selectHeadQuarter}
            renderInput={(params) => (
              <>
                <label className='labelUbication' htmlFor="searchHQ">Sede</label>
                <TextField className='ubicacionInput' {...params} variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }} />
              </>
            )}
          />

          <label className='labelUbication' htmlFor="Ubicacion">Ubicación</label><br />
          <input
            id='Ubicacion'
            value={locationChs == null ? '' : locationChs}
            onChange={e => setLocationChs(e.target.value)}
            placeholder='Escriba aquí Bloque, Piso y/u Oficina en donde se encuentra el bien.'
            type="text"
          />

          <Alert severity="error" style={{ display: displayAlert, width: '100%', marginTop: '1rem' }}>
            El campo Sede no puede estar vacio, por favor completelo para continuar.
          </Alert>

        </DialogContent>
        <DialogActions style={{ justifyContent: 'center', margin: '1rem', marginTop: 0 }}>
          <Button style={{ width: '100px' }} className='btnSecundary' onClick={() => {
            setHeadQuarterChs(saveHeadquarterChs)
            setLocationChs(saveLocationChs)
            setOpen3(false)
          }} color="primary">
            Cancelar
          </Button>
          <Button style={{ width: '100px' }} className='btnPrimary' onClick={handleClose3} color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>);
  }


  /* Alert lost */

  const renderDialogtheftLost = () => {
    return (
      <Dialog className='dialogReounded dialogChangeLost' open={showDialogtheft} aria-labelledby="form-dialog-title">
        <DialogContent className='labelFlex' style={{ borderRadius: '1rem' }}>
          Señor usuario <br /><br />{textOption}
        </DialogContent>
        <DialogActions style={{ alignSelf: 'center', marginBottom: '1.5rem' }}>
          <Button onClick={() => setShowDialogtheft(false)} style={{ width: '100px' }} className='btnPrimary' color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>);
  }

  /* Email */

  const templateText = () => {
    return `
      <p style="color: #5f6273; font-weight: 400; font-size: 0.875rem;">
        Señores Inventarios
        <br><br>
        Se informa que una vez consultada la información del serie ${props.data.serieBien == null ? 'no identificado' : props.data.serieBien}, el sistema identificó la placa ${props.data.placaBien} a nombre de ${props.user.fullName}
        <br><br>
        Se solicita generar la correspondiente placa de codigo de barras por ${razonReimpresion.name}, con el fin de realizar la legalización.
        <br><br>
        ${props.user.fullName}
        <br><br>
        Correo electronico ${props.propsDad.user.email}
      </p>
    `;
  }

  const send = async () => {
    if (editorRef.current) {
      setSended(true)
      let params = {
        subject: asuntoMail,
        emailEnd: email,
      }
      let result = await sendMail(params, editorRef.current.getContent(), ccmail)
      if (result) {
        setEmailAlert(result)
      } else {
        setEmailAlert('')
      }
    }
  };

  const handleChangeTypeReimpresion = (event) => {
    let optionData = props.propsDad.rePrintReasons.find(reimpresion => reimpresion.id == event.target.value)

    setRazonReimpresion(optionData)
  };

  useEffect(() => {
    if (razonReimpresion != null) {
      let seacrh = asuntoMail.split(' - ')
      let asuntoNuevo = seacrh[1] != undefined ?
        asuntoMail.replace(` - ${seacrh[1]}`, ` - por ${razonReimpresion.name}`) :
        asuntoMail + ` - por ${razonReimpresion.name}`

      setAsuntoMail(asuntoNuevo)
    }

  }, [razonReimpresion])


  const renderDialogEmail = () => {
    return (
      <Dialog className='dialogReounded DialogEmail'
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {emailAlert.length == 0 && razonReimpresion != null ? <>
          <DialogTitle style={{ color: '#5f6273', fontWeight: '700', paddingBottom: 0, paddingLeft: '1rem' }}>{"Enviar correo electrónico"}</DialogTitle>
          <DialogContent id='DialogEmail'>
            <label className='labelStyle'>Para</label>
            <TextField type="password" style={{ backgroundColor: 'transparent', color: '#5f6273' }} fullWidth id="placa" value={email} size={'small'} variant="outlined" onChange={e => setEmail(e.target.value)}  disabled />

            <label className='labelStyle'>CC</label>
            <Autocomplete
              multiple
              id="tags-filled"
              options={[]}
              freeSolo
              value={ccmail}
              onChange={(event, input) => {
                setCcEmail(input)
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip variant='outlined' style={{ backgroundColor: validateEmailString(option) ? '#dae2fc' : '#ef4682', color: validateEmailString(option) ? '#445dc9' : '#ffff', border: 0 }} color={validateEmailString(option) ? 'primary' : 'secondary'} label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField style={{ marginBottom: 7, color: '#5f6273' }}
                  className='select-filtros'
                  onBlur={(e) => {
                    if (e.target.value.length > 0) {
                      setCcEmail(ccmail.concat(e.target.value))
                    }
                  }}
                  onKeyDown={e => {
                    if (e.keyCode === 188 && e.target.value) {
                      setCcEmail(ccmail.concat(e.target.value))
                    }
                  }} {...params} type='email' variant="outlined" size={'small'} />
              )}
            />

            <label className='labelStyle'>Asunto</label>
            <TextField style={{ color: '#5f6273', backgroundColor: 'transparent' }} fullWidth id="asunto" value={asuntoMail} size={'small'} variant="outlined" disabled />

            <p style={{ color: '#5f6273', margin: '0.5rem 0' }}>Contenido</p>
            <Editor
              apiKey='4kbnibmrsojzegoh7cxijw4b8sjui7oxr9o3qxq3okpmeq5b'
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={templateText()}
              init={{
                height: 350,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | " +
                  "bold italic backcolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            />
            {emailAlert.length > 0 && <Alert severity={'info'}>
              {emailAlert.message}
            </Alert>}
          </DialogContent>

          {sended === false ?
            <DialogActions className='assetDialogActions1'>
              <Button className='btnSecundary' onClick={toggleDialog} color="primary">
                Cancelar
              </Button>
              <Button className='btnPrimary' onClick={send} color="primary" autoFocus>
                Enviar
              </Button>
            </DialogActions>
            :
            <DialogActions className='assetDialogActions2'>
              <Button className='btnSecundary' onClick={toggleDialog} color="primary">
                Cancelar
              </Button>
              <Button autoFocus className='btnPrimary'>
                {emailAlert.length === 0 ? 'Enviando...' : 'Enviado'}
              </Button>
            </DialogActions>}
        </> : emailAlert.length == 0 ? <DialogContent className='dialogMotivo'>
          <DialogTitle>{"Motivo por el cual está solicitando la renovación de la placa adhesiva"}</DialogTitle>
          <DialogActions>
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <ButtonBase className='ContainerButtonGroup'>
                  <Grid container direction='row'>
                    {props.propsDad.rePrintReasons.length > 0 && props.propsDad.rePrintReasons.map(transact => {
                      return (
                        <Grid key={transact.id} item xs={12} sm={4} container alignItems='center'>
                          <Grid item xs="auto" sm="auto" lg="auto">
                            <BlueRadio
                              checked={razonReimpresion != null && razonReimpresion.id == transact.id}
                              onChange={handleChangeTypeReimpresion}
                              value={transact.id}
                              name={`${transact.id}`}
                            />
                          </Grid>
                          <Grid item xs sm lg="auto">
                            <Typography className='fontAa' variant="caption" display="block" gutterBottom>{transact.name}</Typography>
                          </Grid>
                        </Grid>)
                    })}
                  </Grid>
                </ButtonBase>
              </Grid>
              <Grid item>
                <Button className="btnSecundary btnCancelar" onClick={toggleDialog} color="primary">
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </DialogContent> :
          <>
            <DialogTitle style={{ color: '#5f6273', fontWeight: '700', paddingBottom: 0, paddingLeft: '1rem' }}>
              {emailAlert.error ? 'Error al enviar correo' : 'Enviado correctamente'}
            </DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid item xs={12}>
                  <Typography className='labelFlex' style={emailAlert.error ? {} : { marginBottom: '1rem' }}>{emailAlert.message}</Typography>
                </Grid>
                {emailAlert.error ?
                  <Grid item container>
                    <Grid item>
                      <b className='labelFlex'>Detalle del error:</b>
                    </Grid>

                    <Grid item>
                      <Typography className='labelFlex' style={{ paddingLeft: '1rem' }} paragraph>
                        {emailAlert.messageError}
                      </Typography>
                    </Grid>
                  </Grid>
                  : null}
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Button className='btnPrimary' style={{ margin: 'auto' }} onClick={toggleDialog} autoFocus>
                    Aceptar
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </>
        }
      </Dialog>
    );
  };


  return (
    <div className={classes.root + ' contenedorAsset'} >
      <Paper variant="outlined" style={{ padding: '1rem 0', borderRadius: '2rem', border: 0, margin: 0, maxWidth: 'none', boxShadow: '3px 3px 20px 0px #d6e0f4', position: 'relative' }} className={changeType && headquarterChs && locationChs ? classes.paper : props.isOut ? classes.paperOut : classes.paper}>
        <Grid container className='contenedorAssetGridMayor'>
          <Grid className='contenedorAssetGrid' item xs={12} sm={12} container>
            <Grid className='columnaLogo' item xs sm={3} lg={2} style={{ padding: '0 0.5rem' }} justifyContent='center' container direction="column">
              <IconButton className="asset_icon" >
                <img className={classes.img} alt="Imagen Bien" src={props.url_imagen} />
              </IconButton>
              <Typography align="center" variant="caption" display="block" gutterBottom className='descripcionBien'> {props.data.descripcionBien}</Typography>
            </Grid>
            <Grid item className='iconsAsset iconsAssetTop' xs sm>
              <IconButton style={{ padding: '5px' }} onClick={toggleDialog}>
                <FontAwesomeIcon style={{ color: '#80849d' }} icon={faBarcode} size="1x" />
              </IconButton>

              <IconButton style={{ padding: '5px' }} onClick={toggleDialogPrint} aria-label="delete">
                <PictureAsPdfIcon style={{ color: '#80849d' }} />
              </IconButton>

              <Tooltip style={{ background: '#ef4682' }} title={notifyAlert()} disableHoverListener={li.length <= 0}>
                <IconButton style={{ padding: '0px' }} color={'inherit'} aria-label="add an alarm">
                  <NotificationsActiveIcon style={{ color: li.length > 0 ? '#ef4682' : '#8487a0' }} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid className='columnaInfo' item xs={12} sm={9} lg container>
              <Grid item xs container className='contenedorMedio'>
                <Grid item xs={12} container direction='row'>
                  <Grid item container xs={12}>
                    <Grid item xs={4} sm={2} lg={2} xl={2} container direction='column'>
                      <Grid item>
                        <Typography variant="caption">
                          <b className='bAsset'> Placa: </b>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography style={{ color: '#5f6273' }} variant="caption">
                          <b className='bAsset'> Serie del bien: </b>
                        </Typography>
                      </Grid>
                      <Grid item className='dependenciaXs'>
                        <Typography variant="caption">
                          <b className='bAsset'>Dependencia: </b>
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item xs={8} sm lg xl container direction='column'>
                      <Grid item>
                        <Typography variant="caption" className="textCards">
                          {props.data.placaBien}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography className="textCards" variant="caption">
                          {props.data.serieBien}
                        </Typography>
                      </Grid>
                      <Grid item className='dependenciaXs'>
                        <Typography variant="caption" className="textCards">
                          {props.data.dependencia}
                        </Typography>
                      </Grid>
                    </Grid>

{/*                     <Grid item xs={4} sm={2} lg={2} xl={2} container direction='column'>
                      <Grid item>
                        <Typography variant="caption">
                          <b className='bAsset'>:Estado del bien</b>
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item xs sm container direction='column'>
                      <Grid item>
                        <Typography variant="caption"
                          style={{ color: props.data.stage_color, border: `0.1rem solid ${props.data.stage_color}`, borderRadius: '0.5rem', padding: '0.1rem 0.4rem', fontSize: '0.813rem', fontWeight: '600' }}>
                          {props.data.estadoBien}
                        </Typography>
                      </Grid>
                    </Grid> */}
                  </Grid>

                  <Grid item xs sm lg xl container className='dependenciaLg'>
                    <Grid item xs={4} sm={2} lg={2} xl={2}>
                      <Typography noWrap variant="caption">
                        <b className='bAsset'>Dependencia: </b>
                      </Typography>
                    </Grid>

                    <Grid item xs sm lg xl>
                      <Typography variant="caption" className="textCards">
                        {props.data.dependencia}
                      </Typography>
                    </Grid>
                  </Grid>
                  <FontAwesomeIcon style={{ height: '1.1rem', color: '#5f6273', fontSize: '1.813rem', position: 'absolute', right: '1.5rem', top: '1.5rem' }} icon={faEllipsisV} />
                </Grid>

                <hr className='hrStyle' />

                <Grid item xs={12} container>
                  <Grid item xs={4} sm={2} lg={2} xl={2}>
                    <Typography style={{ color: '#5f6273' }} variant="body2" color="textSecondary" className={classes.text}>
                      <b className='bAsset'>Ubicación del bien: </b>
                    </Typography>
                  </Grid>

                  <Grid item xs sm >
                    <Typography
                      className="textCards" variant="caption" gutterBottom>
                      {headquarterChs != null && headquarterChs.length > 0 ? <>
                        {headquarterChs}<br />
                      </> : null}
                      {locationChs}
                      <Button className='btnEditar1'
                        style={{
                          marginLeft: '0.2rem', padding: 0, width: 'auto', height: '1.375rem',
                          border: `1px solid ${headquarterChs.length > 0 || (locationChs != null && locationChs.length) > 0 ? '#445dc9' : '#ef4682'}`
                        }}
                        onClick={handleChangeSwitch2}
                        variant="outlined"
                        color={(headquarterChs.length > 0 || (locationChs != null && locationChs.length) > 0 ? 'primary' : 'secondary')} size="small"
                      >
                        <p style={{ margin: 0, fontSize: '0.688rem', fontWeight: '600', textTransform: 'none' }}>
                          {headquarterChs.length > 0 ? 'Editar' : locationChs != null ? 'Editar' : 'Agregar'}
                        </p>
                        <EditIcon style={{ fontSize: "0.688rem" }} />
                      </Button>

                      <Button className='btnEditar2'
                        style={{ marginLeft: '0.2rem', padding: 0, width: 'auto', height: '1.375rem' }}
                        onClick={handleChangeSwitch2}
                        color={(headquarterChs.length > 0 || (locationChs != null && locationChs.length) > 0 ? 'primary' : 'secondary')} size="small"
                      >
                        <EditIcon style={{ fontSize: "0.988rem" }} />
                      </Button>
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={12} container direction='row' className='estadosContainer'>
                  <Grid item xs={4} sm={2} lg={2} xl={2} container direction='column' style={{ alignSelf: 'center' }}>
                    <Grid item>
                      <Typography variant="caption">
                        <b className='bAsset'>Estado del bien: </b>
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={8} sm={9} lg={4} xl={4} container justifyContent='space-between' alignContent='center'>
                    <ButtonBase color="primary" className='estado' aria-label="add to shopping cart">
                      <Typography className='fontAa' variant="caption" display="block" gutterBottom>Bueno</Typography>
                      <FormControlLabel
                        control={<MaterialUISwitch sx={{ m: 0 }} />}
                        checked={assetStatus}
                        onChange={handleChangeStatus}
                      />
                      <Typography className='fontAa' variant="caption" display="block" gutterBottom>Regular</Typography>
                    </ButtonBase>
                  </Grid>

                  <Grid item xs={4} sm={2} lg={2} xl={2} container direction='column' style={{ alignSelf: 'center' }}>
                    <Grid item>
                      <Typography variant="caption">
                        <b className='bAsset'>¿Verificado físicamente?:</b>
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={8} sm lg={4} xl={4} container direction='column' justifyContent='center'>
                    <ButtonBase style={{ justifyContent: 'flex-start' }} className='estadoFaltante' color="inherit" display="flex">
                      <BlueRadio
                        checked={changeType == getIdOptionSUSIList('Verificado', props.changesTypes)}
                        onChange={handleChangeType}
                        value={getIdOptionSUSIList('Verificado', props.changesTypes)}
                        name="radio-button-demo"
                      />
                      <Typography className='fontAa' variant="caption" display="block" gutterBottom>Si</Typography>

                      <RedRadio
                        checked={changeType == getIdOptionSUSIList('Faltante', props.changesTypes)}
                        onChange={handleChangeType}
                        value={getIdOptionSUSIList('Faltante', props.changesTypes)}
                        name="radio-button-demo"
                      />
                      <Tooltip title={observations != null ? observations : ' '} disableHoverListener={observations == null || changeType != getIdOptionSUSIList('Faltante', props.changesTypes)}>
                        <Typography className='fontAa' variant="caption" display="block" gutterBottom>
                          No{changeType == getIdOptionSUSIList('Faltante', props.changesTypes) ? ': ' + getNameOptionSUSIList(missingReason, props.missingReasons) : ''}
                        </Typography>
                      </Tooltip>
                    </ButtonBase>
                  </Grid>
                </Grid>

                <Grid item container alignItems='center' justifyContent='space-between'>
                  <Grid item xs={4} sm={2} lg={2}>
                    {props.data.date_checklist != null && new Date(props.data.date_checklist).getFullYear() == new Date().getFullYear() ?
                      <CheckCircleOutlineIcon style={{ color: '#46cb4c', fontSize: '1.5rem', marginRight: '0.5rem' }} /> // verde
                      : props.data.date_checklist !== null && new Date() >= new Date(`${1}/${1}/${culminationDate.getFullYear()}`) && new Date() < culminationDate ?
                        <CheckCircleOutlineIcon style={{ color: '#EAB130', fontSize: '1.5rem', marginRight: '0.5rem' }} /> // amarillo
                        :
                        <CheckCircleOutlineIcon style={{ color: '#ef4682', fontSize: '1.5rem', marginRight: '0.5rem' }} /> // rojo
                    }
                    <Typography variant="caption" className="textCards">
                      Última verificación:
                    </Typography>
                  </Grid>

                  <Grid item xs sm>
                    <Typography variant="caption" className="textCards">
                      {props.data.date_checklist != null ? new Date(props.data.date_checklist).toLocaleDateString("es-ES", configDate) : 'No Registrado'}
                    </Typography>
                  </Grid>

                  <Grid item xs={3} sm={4} lg={2} xl={2} container className='iconsAsset iconsAssetBottom' justifyContent='flex-end'>
                    <Grid item xs sm="auto" lg="auto" xl={2}>
                      <IconButton style={{ padding: '0px' }} onClick={toggleDialog}>
                        <FontAwesomeIcon style={{ color: '#80849d' }} icon={faBarcode} size="1x" />
                      </IconButton>
                    </Grid>

                    <Grid item xs sm="auto" lg="auto" xl={2}>
                      <IconButton style={{ padding: '0px' }} onClick={toggleDialogPrint} aria-label="delete">
                        <PictureAsPdfIcon style={{ color: '#80849d' }} />
                      </IconButton>
                    </Grid>

                    <Grid item xs sm="auto" lg="auto" xl={2}>
                      <Tooltip style={{ background: '#ef4682' }} title={notifyAlert()} disableHoverListener={li.length <= 0}>
                        <IconButton style={{ padding: '0px' }} color={'inherit'} aria-label="add an alarm">
                          <NotificationsActiveIcon style={{ color: li.length > 0 ? '#ef4682' : '#8487a0' }} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      {renderDialogStatusBad()}
      {renderDialogChangeTypeLost()}
      {renderDialogLocation()}
      {renderDialogShare()}
      {renderDialogEmail()}
      {renderDialogPrint()}
      {renderDialogtheftLost()}
    </div >
  );
};

export default AssignedAsset