import React, { Component } from 'react';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

//import './style.css';
import "../../assets/css/online-inventory.css";
import { connect } from 'react-redux';
import { fetchAssignedAssets } from '../../redux/actions/assignedAssetActions';
import { debounce } from 'lodash';
import { CircularProgress } from '@material-ui/core';

class Autocomplete extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            timeOutID: null,
            textSearch: '',
            isOpen: false,

        };
    }

    debounceSearch(e) {
        let paramActual = this.props.params;
        paramActual.page = 1;
        paramActual.descripcionBien = e;
        this.props.dispatch(fetchAssignedAssets(paramActual));
    }

    debounceLog = debounce(textBase => this.debounceSearch(textBase), 300);

    handleChange(e) {
        this.setState({ textSearch: e.target.value });
        this.debounceLog(e.target.value);
    }

    render() {
        return (
            <div className="main-container contenedorBuscador">
                <div className="main-container" style={{ backgroundColor: '#F0F2FA', borderRadius: '1rem', alignItems: 'center', marginRight:'7%', padding: '0' }}>
                    <div className="container-icon">
                        <SearchIcon style={{ color: '#828490' }} />
                    </div>
                    <InputBase
                        placeholder="Buscar"
                        value={this.state.textSearch}
                        style={{ fontWeight: '500', color: '#828490' }}
                        onChange={e => this.handleChange(e)}
                    />
                    <div style={{ marginInlineStart: 'auto', placeSelf: 'center', paddingTop: '0.3rem' }} className="container-icon">
                        {this.props.assignedAssets.isFetching && <CircularProgress disableShrink size='1rem' style={{ backgroundColor: 'trasparent' }}></CircularProgress>}
                    </div>
                </div>
                { /*isOpen &&
                <Paper className="container-results" square>
                    {suggestions.map(suggestion =>
                    <MenuItem
                        key={suggestion.id}
                        component="div"
                        onClick={() => {
                            onChangeSelection(suggestion.title);
                            this.setState({ isOpen: false });
                        }}
                    >
                        {suggestion.title}
                    </MenuItem>)}
                    </Paper>*/}
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    user: state.user,
    params: state.getActualParams,
    assignedAssets: state.assignedAssets
})
export default connect(mapStateToProps)(Autocomplete);