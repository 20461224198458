import { FETCH_ASSETS_AUTH_USER_ERROR, FETCH_ASSETS_AUTH_USER_REQUEST, FETCH_ASSETS_AUTH_USER_SUCCESS } from "../types"
import {REACT_APP_BACKEND_URL} from "../../config";

import axios from 'axios';

export const fetchLogin = (params) => (dispatch) => {
     console.log(params)
    dispatch({ type: FETCH_ASSETS_AUTH_USER_REQUEST })
    axios.post(REACT_APP_BACKEND_URL + '/getUsuario.php', params).then(res => {
        dispatch({
            type: FETCH_ASSETS_AUTH_USER_SUCCESS,
            payload: {
                token: res.data.access_token,
                culmination_date: res.data.CULMINATION_DATE,
                limit_file_size: res.data.LIMIT_FILE_SIZE
            }
        });
        if (res.data.access_token) {     
            window.sessionStorage.setItem('_displayName', res.data.displayName);
            window.sessionStorage.setItem('_fullName', res.data.fullName);
            window.sessionStorage.setItem('_displayMail', res.data.mail);
            window.sessionStorage.setItem('_userToken', res.data.access_token);
            window.sessionStorage.setItem('_numeroDocumento', res.data.numero_documento);
            window.sessionStorage.setItem('CORREO_REPORTAR_REIMPRESION_PLACA', res.data.CORREO_REPORTAR_REIMPRESION_PLACA);
            window.sessionStorage.setItem('CORREO_REPORTAR_SOBRANTES', res.data.CORREO_REPORTAR_SOBRANTES);
            window.sessionStorage.setItem('NOTI_CULMINATION_DATE', res.data.NOTI_CULMINATION_DATE);
            window.sessionStorage.setItem('SESION_CULMINATION_TIME', new Date());
            window.sessionStorage.setItem('LIMIT_FILE_SIZE', res.data.LIMIT_FILE_SIZE);
            window.sessionStorage.setItem('CORREO_GESTION_FALTANTES', res.data.CORREO_GESTION_FALTANTES);
            console.log("antes de ir");
            //dispatch(dataUser(res.data.numero_documento)).then(() => {
            //console.log("cuando llega");
            window.location.reload();
            // });
            // dispatch(dataUser(res.data.numero_documento)); //TLA cambio
            // console.log("cuando llega");
            // window.location.reload();  
        } else {

            window.sessionStorage.removeItem('_userToken');
        }
    }).catch(e => {
        console.error('Error al iniciar sesión: ',e);
        dispatch({
            type: FETCH_ASSETS_AUTH_USER_ERROR,
            payload: {
                errorMessage: e.toString()
            }
        })
    })
}
