import axios from 'axios';

export const sendMail = async (params, content, cc = []) => {
    let paramsStrings = "";        
    for (const param in params) {
        if(paramsStrings.length>0){
            paramsStrings += "&";
        }
        paramsStrings += param+"="+params[param];
    }

    return axios.post(process.env.REACT_APP_BACKEND_URL+'/sendMail?'+paramsStrings, { content, cc }).then(resp => {
        return resp.data
    }).catch(e => {
        console.error('Error al enviar mail: ',e);
        return e.toString()
    })
}